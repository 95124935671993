import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { FormContext, useForm } from 'react-hook-form';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useReward } from 'react-rewards';
import { usePreviousValue } from 'beautiful-react-hooks';

import {
  Box,
  Card,
  Flex,
  Text,
  MdIcon,
  Button,
  LinkButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tooltip,
  Palette,
  Spinner,
  Stack,
  chakra,
  useTheme,
} from '@workshop/ui';

import {
  commonUtils,
  hooks,
  getParamFromUrl,
  videoUtils,
  analytics,
} from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';
import navRoutes from 'navigation/Routes';

import { PLATFORM, contactUrl } from 'constants/env';
import { CLIP_NAME } from 'constants/common';
import { PRO_ORGS } from 'constants/organisation';
import {
  ISessionFormat,
  SESSION_FORMAT,
  SESSION_TYPE,
  SESSION_STEP_TYPE,
} from 'constants/courses';

import {
  stepQuestionActions,
  sessionActions,
  stepActions,
  videoClipActions,
  courseActions,
} from 'redux/actions/cms';
import { assistantActions } from 'redux/actions/common';
import {
  getStepsForSession,
  getVideoClipsForSession,
  getVideoClipsForStep,
  getVideoClipsForSteps,
  getCategoryOptions,
  useCurrentTeamProfile,
} from 'redux/selectors';
import { useHasPermission, useHasRole } from 'redux/selectors/organisation';
import { useUploadList } from 'redux/selectors/background';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import {
  formattedRequirementData,
  groupByStepType,
  DraggableStep,
  getExpandedVideoClipIds,
  formatVideoClipData,
  DraggableClip,
  generatePlayerSteps,
  generatePlayerChecklist,
} from 'screens/cms/SessionEdit/src/dataUtils';

import {
  SectionTitle,
  FixedFooter,
  InformationCard,
  InPageNav,
  InPageNavTab,
  Tour,
  ConfirmModal,
  ProCta,
  OnboardingChecklist,
} from 'components/Common';
import { IDraggableData } from 'components/Draggable';
import { OverviewCard, OverviewTextArea } from 'components/OverviewCard';
import { FurtherDetailsSessionCard } from 'components/FurtherDetailsCard';
import { PromptItem, IAddItem, MCQFormData } from 'components/ListItem';
import { FormCard } from 'components/FormCard';
import { SessionPlayer } from 'components/SessionPlayer';
import { CheckList } from 'components/SessionPlayer/CheckList';
import { Loading } from 'components/Loading';

import { PromptFormData } from 'components/ListItem';

import { GlobalState } from 'types';
import { PERMISSION_SLUGS, CompleteUploadChunkAction } from 'types/common';
import {
  DescriptionSessionFormData,
  ExerciseNoteSessionFormData,
  FurtherDetailsSessionFormData,
  OverviewSessionFormData,
  RequirementSessionFormData,
  StepUpdateFormData,
  ISession,
  IStepListItem,
  IVideoClip,
} from 'types/cms';

import {
  Requirement,
  StepEdit,
  VideoClipsUpload,
  VideoClipsList,
  VideoClipItem,
} from 'screens/cms/SessionEdit';

import ShareSessionModal from './ShareSessionModal';
import SessionManualPlan from './SessionManualPlan';
import SyncedSessionModal from './SyncedSessionModal';

// Routing Props
interface MatchParams {
  sessionId: string;
  courseId?: string;
  planSlug?: string;
}

type SessionFormData = FormData | Partial<ISession>;

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const sessionFormatOptions: { [key in Partial<ISessionFormat>]: string } = {
  reflect: 'Reflective',
  longform: 'Extended',
  practice: 'Practice',
  guided: 'Guided',
  research: 'Research (Coming Soon)',
  challenge: 'Challenge (Coming Soon)',
  assessment: 'Assessment (Coming Soon)',
};

export const tourIds = {
  // sessionBuilder: 'sessionBuilder',
  // sessionMediaType: 'sessionMediaType',
  // sessionIntroRecord: 'sessionIntroRecord',
  // sessionSummaries: 'sessionSummaries',
  // sessionFirstStep: 'sessionFirstStep',
  // sessionOutlineView: 'sessionOutlineView',
  // sessionPublish: 'sessionPublish',
  sessionBuilderOverview: 'sessionBuilderOverview',
  sessionOutlineViewButton: 'sessionOutlineViewButton',
  sessionOutlineSteps: 'sessionOutlineSteps',
  sessionOutlineBit: 'sessionOutlineBit',
  sessionOutlineBitDrag: 'sessionOutlineBitDrag',
  sessionStepperViewButton: 'sessionStepperViewButton',
  sessionStepperView: 'sessionStepperView',
  sessionStepperSteps: 'sessionStepperSteps',
  sessionStepperBits: 'sessionStepperBits',
  sessionStepperMedia: 'sessionStepperMedia',
  sessionChecklist: 'sessionChecklist',
  sessionPublishButton: 'sessionPublishButton',
};

const SessionEditScreen: React.FC<Props> = ({
  match: { params },
  questions,
  moduleQuestions,
  questionIds,
  session,
  course,
  sessionUI,
  categoryOptions,
  steps,
  stepsUI,
  videoClips,
  unassignedVideoClips,
  courseParam,
  videoClipUI,
  location,
  history,
}) => {
  const { sessionId, planSlug } = params;

  const currentTeamProfile = useCurrentTeamProfile();

  const currentStepId = getParamFromUrl(location, 'step');
  const popupParam = getParamFromUrl(location, 'p');

  // Determine whether the user has editing permissions and whether the
  // unit is open for editing
  const hasEditPermissions = useHasPermission(
    PERMISSION_SLUGS.can_edit_content
  );

  const hasSummaryEditPermissions = useHasPermission(
    PERMISSION_SLUGS.can_edit_clip_summaries
  );

  const hasAdminRole = useHasRole('admin');

  // Constants to define what aspects of the UI should be editable
  const isLockedForEditing = session && session.isLockedForEditing;

  const isEditingDisabled = !hasEditPermissions || isLockedForEditing;

  const isTextEditingDisabled =
    !hasSummaryEditPermissions || isLockedForEditing;

  const [mutliVideoUpload, setMultiVideoUpload] = useState<{
    visible: boolean;
    error: string | null;
  }>({
    visible: false,
    error: null,
  });

  const [uploadingFileNames, setUploadingFileNames] = useState<string[] | null>(
    null
  );
  const [pollingInProgress, setPollingInProgress] = useState(false);
  const [currentView, setCurrentView] = useState('builder');
  const [tabIndex, setTabIndex] = useState(0);
  const [loadingSessionPlayer, setLoadingSessionPlayer] = useState(false);
  const [showShareModal, setShowShareModal] = useState(popupParam === 'share');
  const [showSyncedModal, setShowSyncedModal] = useState(false);
  const [syncedModalState, setSyncedModalState] = useState<'info' | 'copy'>(
    'info'
  );
  const [showSummaryConfirm, setShowSummaryConfirm] = useState(false);
  const [generatedSummaries, setGeneratedSummaries] = useState('');
  const [triggerOpenProPopup, setTriggerOpenProPopup] = useState<
    boolean | undefined
  >(undefined);
  const [isBuilding, setIsBuilding] = useState(false);

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const dispatch = useDispatch();
  const methods = useForm();

  const uploadList = useUploadList();

  const { reward, isAnimating: isRewardAnimating } = useReward(
    'publish',
    'confetti',
    {
      lifetime: 50,
      startVelocity: 15,
      colors: [
        Palette.blue['300'],
        Palette.blue['400'],
        Palette.green['300'],
        Palette.green['400'],
        Palette.orange['200'],
        Palette.red['300'],
        Palette.neutral['10'],
      ],
    }
  );

  // TODO: Temporary - register all step related form data separately
  const {
    handleSubmit: stepHandleSubmit,
    register: stepRegister,
    reset: stepReset,
    formState: stepFormstate,
    errors: stepErrors,
    setValue: stepSetValue,
  } = useForm<StepUpdateFormData>();

  // Temporary - register all video clip related form data separately
  const videoClipMethods = useForm<{ [key: string]: string | FileList }>();
  const {
    handleSubmit: clipHandleSubmit,
    formState: clipFormState,
    errors: clipErrors,
  } = videoClipMethods;

  // Initialise some local state which can be used to control the UI
  // during data update requests to the API
  const [isUpdating, setIsUpdating] = useState({
    overview: false,
    furtherDetails: false,
    description: false,
    requirements: false,
    exercises: false,
    publishStatus: false,
  });

  // Control which steps & related clips are displayed
  const [expandedStep, setExpandedStep] = useState<string | null>(null);
  const [expandedClipIds, setExpandedClipIds] = useState<DraggableClip[]>([]);

  // The session format dictates how the `SessionEdit` screen is rendered/what
  // functionality is available within the interface
  const [selectedSessionFormat, setSessionFormat] = useState(
    session?.moduleFormat
  );

  // When a new video is added to a clip, `thumbnailPollState` is used to
  // keep track of how many times we have polled the API for an updated
  // version of the clip until the clip is returned with a thumbnail present.
  //
  // It's also used to store the current thumbnail of an existing clip so that
  // we can detect when thumbnails have changed.
  const [thumbnailPollState, setThumbnailPollState] = useState<{
    [id: string]: {
      pollCount: number;
      thumbnail: string | null;
      complete: boolean;
    };
  }>({});

  useEffect(() => {
    if (currentView === 'builder') {
      analytics.track('Viewed Session Builder');
    }
    if (currentView === 'details') {
      analytics.track('Viewed Session Details');
    }
    if (currentView === 'todo') {
      analytics.track('Viewed Session To Do List');
    }
  }, [currentView]);

  useEffect(() => {
    if (tabIndex === 0) {
      analytics.track('Viewed Session Outline View');
    }
    if (tabIndex === 1) {
      analytics.track('Viewed Session Stepper View');
    }
  }, [tabIndex]);

  /** ------------ DATA LOADING ------------ */
  const { session: sessionLoading, steps: stepsLoading } =
    hooks.useLoadingDataState(
      {
        session: {
          actions: [() => sessionActions.retrieve(parseInt(sessionId))],
        },
        steps: {
          actions: [() => stepActions.list(parseInt(sessionId))],
        },
        videoClips: {
          actions: [() => videoClipActions.list(parseInt(sessionId))],
        },
      },
      [sessionId]
    );

  hooks.useLoadingDataState(
    {
      questionsLoading: {
        actions: questionIds.length
          ? [() => stepQuestionActions.list(questionIds)]
          : [],
        startLoading: !Boolean(stepsLoading),
      },
    },
    [...questionIds, stepsLoading]
  );

  const { course: courseLoading } = hooks.useLoadingDataState(
    {
      course: {
        actions: session?.standaloneCourseDetails
          ? // @ts-ignore
            [() => courseActions.retrieve(session.standaloneCourseDetails.id)]
          : [],
      },
    },
    [session]
  );

  const stepsArr = Object.values(steps);

  const loadSteps = async () => {
    setLoadingSessionPlayer(true);
    await Promise.all(stepsArr.map((step) => loadStepData(`${step.id}`)));
    setLoadingSessionPlayer(false);
  };

  useEffect(() => {
    if (
      currentView !== 'builder' ||
      stepsLoading ||
      stepsArr.length === 0
      // tabIndex !== 1
    ) {
      return;
    }
    loadSteps();
  }, [currentView, stepsLoading, stepsArr.length]);

  // Init our chunk upload generator
  const createChunkUpload = hooks.useDirectUpload(isEditingDisabled);

  // If the session has an intro step then the handling of step
  // labels & indexes changes
  const hasIntroStep = !!Object.values(steps).find(
    (step) => step.stepType === SESSION_TYPE.intro
  );

  // Collect & format the list of step data by step type
  //
  // TODO: Move to state or memoize
  const {
    normal: normalSteps,
    intro: introSteps,
    outro: outroSteps,
  } = groupByStepType(steps, () => {}, hasIntroStep);

  // A session should only have 1 (or none) intro step and 1 (or none)
  // outro step, so extract them
  const [intro] = introSteps;
  const [outro] = outroSteps;

  // Extract the video clips for the selected step. If the
  // video clips for the selected step haven't yet been loaded this
  // will return an empty array.
  //
  // TODO: Move to state or memoize
  const expandedStepData = expandedStep ? steps[expandedStep] : null;
  const expandedStepVideoClipData = expandedStep
    ? getVideoClipsForStep(videoClips, parseInt(expandedStep))
    : {};

  // When a step is expanded, generate an array of objects containing
  // nothing but the clip ID. This data is passed to the Draggable component
  // which displays our list of clips.
  //
  // The Draggable component then uses the array of clip IDs to populate
  // the 'real' data for the clips shown. This is done by using the clip IDs
  // to pull data in from out `expandedStepVideoClipData` variable. Using
  // this method our video clip data is rendered based on this state array
  // which only changes when the remote data (expandedStepVideoClipData)
  // updates. As a result we don't experience any 'flicker' in the video
  // clip data as it gets updated via the API.
  hooks.useDeepEqualEffect(() => {
    const expandedVideoClipIds = expandedStep
      ? getExpandedVideoClipIds(
          expandedStepVideoClipData,
          parseInt(expandedStep)
        )
      : [];

    setExpandedClipIds((prevState) => [
      ...expandedVideoClipIds,
      // Ensure any optimistic/temporary clips remain untouched
      ...prevState.filter((clip) => clip.id.toString().startsWith('clip')),
    ]);
  }, [expandedStep, expandedStepVideoClipData]);

  // Detect whether a thumbnail has been added to our video clips
  // on each poll response. On each poll response, the screen will
  // re-render and our `pollingClipData` will be re-calculated
  // based on the latest response from the API.
  const pollingClipData = Object.keys(thumbnailPollState).map(
    (id) => videoClips[id]
  );
  // TODO: The design of this effect will lead to some potentially
  // nasty race conditions as it could run multiple times in parallel.
  //
  // Ideally the polling would be handled using some sort interval, or
  // controlled way to not allow parallel calls.
  hooks.useDeepEqualEffect(() => {
    // Prevent parallel polling processes from running. Only start polling
    // again if we aren't already currently polling.
    if (pollingInProgress || !pollingClipData.length) return;

    const pollStateValues = Object.values(thumbnailPollState);

    const hasIncompletePolling =
      pollingClipData.length < pollStateValues.length ||
      pollStateValues.find((val) => !val.complete);

    // All thumbnails have been fetched, nothing to do
    if (!hasIncompletePolling) return;

    setPollingInProgress(true);

    // If the `pollingClipData` has changed then we will loop
    // through the clip IDs and send a GET request for each
    // ID if the clip in our state has no thumbnail
    //
    // TODO: Cleanup `thumbnailPollState` if a clip has transitioned
    // from no thumbnail -> thumnail remove if from the array - this
    // already happens to a degree but can it 'error'?
    const newState: typeof thumbnailPollState = {};

    Promise.all(
      pollingClipData
        .filter((a) => a)
        .map(async (clip) => {
          const { id } = clip;
          const pollStateForClip = thumbnailPollState[id.toString()];

          // If the clip has no thumbnail and we've attempted
          // to retrieve the thumbnail less than 10 times
          if (
            clip.videoThumbnail === null &&
            clip.videoThumbnail === pollStateForClip.thumbnail &&
            pollStateForClip.pollCount < 10
          ) {
            await dispatch(videoClipActions.retrieve(id));

            // We build a 'new' copy of the `thumbnailPollState` from scratch
            // so that we can update it in a single state update at the end of
            // the effect.

            // If there are no poll requests made then the `thumbnailPollState`
            // will be reset.
            newState[id.toString()] = {
              pollCount: pollStateForClip.pollCount + 1,
              thumbnail: pollStateForClip.thumbnail,
              complete: false,
            };
          } else {
            newState[id.toString()] = {
              pollCount: pollStateForClip.pollCount,
              thumbnail: clip.videoThumbnail,
              complete: true,
            };
          }
        })
    ).then(() => {
      if (!isEqual(newState, thumbnailPollState)) {
        setThumbnailPollState(newState);
        setPollingInProgress(false);
      }
    });
  }, [pollingClipData, pollingInProgress]);

  const handleSaveOverview = async (data: OverviewSessionFormData) => {
    const formData: SessionFormData = new FormData();
    if (data.landscape) {
      formData.append('image', data.landscape);
    }
    if (data.portrait) {
      formData.append('image_portrait', data.portrait);
    }
    formData.append('title', data.title);
    if (data.description) {
      formData.append('description', data.description);
    }

    setIsUpdating({ ...isUpdating, overview: true });
    await dispatch(sessionActions.update(parseInt(sessionId), formData));
    // Replicate changes to parent course if the session is standalone
    if (standaloneCourse) {
      if (data.landscape) {
        formData.delete('image');
        formData.append('image_landscape', data.landscape);
      }
      await dispatch(courseActions.update(standaloneCourse.id, formData));
    }
    setIsUpdating({ ...isUpdating, overview: false });
    analytics.track('Session Details Edited');
  };

  const handleSaveFurtherDetails = async (
    data: FurtherDetailsSessionFormData
  ) => {
    const formData: SessionFormData = {
      moduleFormat: data.moduleFormat,
      duration: data.sessionDuration,
      requiresUpload: data.requiresUpload,
    };
    setIsUpdating({ ...isUpdating, furtherDetails: true });
    await dispatch(sessionActions.update(parseInt(sessionId), formData));
    setIsUpdating({ ...isUpdating, furtherDetails: false });
    analytics.track('Session Details Edited');
  };

  const handleSaveDescription = async (data: DescriptionSessionFormData) => {
    const formData: SessionFormData = {
      description: data.description,
    };
    setIsUpdating({ ...isUpdating, description: true });
    await dispatch(sessionActions.update(parseInt(sessionId), formData));
    setIsUpdating({ ...isUpdating, description: false });
    analytics.track('Session Details Edited');
  };

  const handleSaveRequirement = async (data: RequirementSessionFormData) => {
    const formData: SessionFormData = {
      // @ts-ignore
      checkList: formattedRequirementData(data, session?.checkList[0]),
    };
    setIsUpdating({ ...isUpdating, requirements: true });
    await dispatch(sessionActions.update(parseInt(sessionId), formData));
    setIsUpdating({ ...isUpdating, requirements: false });
    analytics.track('Session Details Edited');
  };

  const handleSaveExercises = async (data: ExerciseNoteSessionFormData) => {
    const formData: SessionFormData = {
      exerciseText: data.exercise,
    };
    setIsUpdating({ ...isUpdating, exercises: true });
    await dispatch(sessionActions.update(parseInt(sessionId), formData));
    setIsUpdating({ ...isUpdating, exercises: false });
    analytics.track('Session Details Edited');
  };

  const handleCancel = () => {};

  const handleStepReorder = (data: IDraggableData<DraggableStep>) => {
    data.forEach((item, idx) => {
      if (!item.hasChanged || !steps[item.id]) return;
      // If there is an intro step, offset by 2, not 1. `idx` is 0-based whereas
      // our step indexes are 1-based. Only normal steps can be re-ordered which
      // is why a step with `idx` 0 will either have an index of 1 (no intro step)
      // or an index of 2 (with intro step)
      const offset = hasIntroStep ? 2 : 1;
      dispatch(stepActions.update(parseInt(item.id), { index: idx + offset }));
    });
    analytics.track('Step Edited');
  };

  const handleClipReorder = (data: IDraggableData<DraggableClip>) => {
    data.forEach((item, idx) => {
      if (!item.hasChanged) return;
      // TODO: Determine if the below is required anymore - video clips should always
      // exist rather than existing as a 'temporary' clip

      // If the id of the item is a number then we know that the clip being re-ordered
      // exists in the backend, so we update the index.
      //
      // If the id of the item is a string then we know the clip doesn't yet exist in
      // the backend and so we create the clip first.
      if (typeof item.id === 'number') {
        dispatch(videoClipActions.update(item.id, { index: idx + 1 }));
      } else {
        if (expandedStep) {
          dispatch(
            videoClipActions.create(
              {
                sessionId: parseInt(sessionId),
                stepId: parseInt(expandedStep),
              },
              { index: idx + 1 },
              true
            )
          );
          analytics.track('Clip Created');
        }
      }
    });
    analytics.track('Step Edited');
  };

  const loadStepData = async (id: string) => {
    // Get Step
    await dispatch(stepActions.retrieve(parseInt(id)));
    // Get Video Clip list
    await dispatch(videoClipActions.list(parseInt(sessionId), parseInt(id)));
  };

  const onStepExpanded = async (id: string) => {
    // If the step is already expanded, reset to null to close
    // the expanded step. Otherwise expand the selected step id.
    const isExpanding = expandedStep !== id;
    setExpandedStep(isExpanding ? id : null);
    setExpandedClipIds([]);
    // Only load the data if we're expanding the step
    if (isExpanding) {
      await loadStepData(id);
    }
  };

  const handleAddStep = async (
    data: IAddItem,
    addClip?: boolean,
    noToast?: boolean
  ) => {
    const formData: Partial<IStepListItem> = {
      title: data.inputText,
      stepType: 'normal',
    };

    const newStep = await dispatch(
      stepActions.create(parseInt(sessionId), formData, noToast)
    );

    const newStepId =
      newStep?.payload && 'result' in newStep?.payload
        ? newStep?.payload.result
        : null;

    // Outro index will have incremented after adding
    // a step so fetch it to update UI
    await loadStepData(outro.id);

    if (newStepId && addClip) {
      await handleAddClip(newStepId.toString(), undefined, true);
    }
    analytics.track('Step Created');
    return newStepId;
  };

  const handleUpdateStep = async (stepId: string, data: StepUpdateFormData) => {
    const formData = {
      title: data.title,
      notes: data.supportingNotes,
    } as const;

    await dispatch(stepActions.update(parseInt(stepId), formData));

    // When updating/saving a step we also loop through the video clip data
    // for that step and determine which video clips need updating or creating.
    //
    // No video related data is updated here - this is handled by our
    // `handleAddMediaToClip` function.
    clipHandleSubmit((data) => {
      // Transform the video clip form data into a format the API will accept
      let clipData = formatVideoClipData(data);
      // Loop through the formatted data
      Object.keys(clipData).forEach(async (id) => {
        // Only update/create a clip if the data has changed. Since 'summary'
        // is the only field which can be updated here, we do a simple equality
        // check between the form data and the existing data in the state.
        if (
          clipData[id].summary &&
          clipData[id].summary !== videoClips[id]?.summary
        ) {
          const clipFormData = { summary: clipData[id].summary };

          // A 'new' video clip will have the format 'clip-{step-id}-{index}'
          // otherwise we know we're updating an existing clip
          if (id.startsWith('clip')) {
            const stepId = id.split('-')[1];
            const index = id.split('-')[2];

            const clipResponse = await dispatch(
              videoClipActions.create(
                {
                  sessionId: parseInt(sessionId),
                  stepId: parseInt(stepId),
                },
                { ...clipFormData, index: parseInt(index) + 1 },
                true
              )
            );

            analytics.track('Clip Created');

            // `clipResponse` will be undefined if the user does not have permission
            // to perform the action.
            if (!clipResponse) return;

            const { payload } = clipResponse;
            // Following the successful creation of the video clip object,
            // replace the optimistic/temporary clip ID from the list of
            // expanded clip IDs with the real one and use the ID returned
            // from the backend to start the thumbnail polling process.
            if (payload && 'result' in payload) {
              const { result } = payload;

              setExpandedClipIds((prevState) => [
                ...prevState.filter((clipId) => clipId.id !== id),
                { id: result },
              ]);
            }
          } else {
            await dispatch(videoClipActions.update(parseInt(id), clipFormData));
            analytics.track('Clip Summary Edited');
          }
        }
      });
    })();
    analytics.track('Step Edited');
  };

  const handleAddClip = async (
    stepId?: string,
    data?: Partial<VideoClipItem>,
    noToast?: boolean
  ) => {
    if (!expandedStep && !stepId) return;

    // const clipIndex = stepId
    //   ? steps[stepId]?.clipCount || 1
    //   : expandedClipIds.length + 1;

    // Add a clip to the currently expanded step
    await dispatch(
      videoClipActions.create(
        {
          sessionId: parseInt(sessionId),
          stepId: stepId ? parseInt(stepId) : parseInt(expandedStep as string),
        },
        { ...(data ? data : {}) },
        // { index: clipIndex, ...(data ? data : {}) },
        noToast
      )
    );
    // Fetch step to update clip counts
    stepId && dispatch(stepActions.retrieve(parseInt(stepId)));
    analytics.track('Clip Created');
  };

  const handleAddMediaToClip = async (
    file: File,
    /** The ID of the clip */
    id: string,
    mediaType: 'video' | 'audio' = 'video'
  ) => {
    const chunkUpload = createChunkUpload(file.name, file.size, { id });

    const response = await chunkUpload.startUpload<CompleteUploadChunkAction>(
      file
    );

    // If chunked uploads are disabled (e.g. due to permissions) then calling
    // `startUpload` will result in a void response
    if (!response) return;

    const { payload } = response;

    // Only run the remaining code if the upload was successful. A successful
    // upload will contain the 'file' property which we then use to finalize
    // the upload process
    if (!payload || !('file' in payload)) return;

    if (mediaType === 'audio') {
      const { file: audioFile, filename } = payload;
      // The `file` included in the successful upload response gives us the full
      // path to the uploaded file. We want to add this to our video clip to 'link'
      // the uploaded file to a video clip object.
      await dispatch(
        videoClipActions.update(parseInt(id), {
          audio: audioFile,
          originalFilename: filename,
        })
      );
    } else {
      const { file: videoFile, filename } = payload;
      // The `file` included in the successful upload response gives us the full
      // path to the uploaded file. We want to add this to our video clip to 'link'
      // the uploaded file to a video clip object.
      await dispatch(
        videoClipActions.update(parseInt(id), {
          video: videoFile,
          originalFilename: filename,
        })
      );
    }

    // Following the successful update of the video clip object,
    // start the thumbnail polling process.
    setThumbnailPollState((prevState) => ({
      ...prevState,
      [id]: {
        pollCount: 0,
        thumbnail: videoClips[id]?.videoThumbnail,
        complete: false,
      },
    }));
    return;
  };

  const handleAddMediaFromInput = async (
    e: React.ChangeEvent<HTMLInputElement>,
    /** The ID of the clip */
    id: string,
    mediaType: 'video' | 'audio' = 'video'
  ) => {
    e.preventDefault();

    const files = e?.target?.files;

    if (!files) return;

    const file = files[0];
    analytics.track('Clip Uploaded', {
      media_type: mediaType,
      file_source: 'upload',
    });
    return await handleAddMediaToClip(file, id, mediaType);
  };

  const handleMultipleClipUpload = (files: File[]) => {
    setUploadingFileNames(files.map((f) => f.name));

    const pollState: {
      [key: string]: {
        pollCount: number;
        thumbnail: string | null;
        complete: boolean;
      };
    } = {};

    const uploadPromises = files.map((file) => async () => {
      const fileType = file.type.replace(/\/.*/, '');
      if (
        fileType !== 'audio' &&
        fileType !== 'video' &&
        fileType !== 'image'
      ) {
        return null;
      }
      const clipResponse = await dispatch(
        videoClipActions.create(
          {
            sessionId: parseInt(sessionId),
          },
          {
            clipType: fileType,
          }
        )
      );

      // TODO: Handle clip creation errors
      // `clipResponse` will be undefined if the user does not have permission
      // to perform the action.
      if (!clipResponse || clipResponse?.error) return null;

      const { payload: clipPayload } = clipResponse;

      if (!clipPayload || !('result' in clipPayload)) return null;

      if (fileType === 'audio' || fileType === 'video') {
        await handleAddMediaToClip(
          file,
          clipPayload.result.toString(),
          fileType
        );
      } else {
        await handleSaveClip(clipPayload.result.toString(), {
          image: file,
        });
      }
      analytics.track('Clip Uploaded', {
        media_type: fileType,
        file_source: 'upload',
      });

      pollState[clipPayload.result.toString()] = {
        pollCount: 0,
        thumbnail: null,
        complete: false,
      };

      return null;
    });

    return commonUtils
      .resolveQueue(uploadPromises)
      .then(() => {
        setThumbnailPollState((prevState) => ({
          ...prevState,
          ...pollState,
        }));
        analytics.track('Clip Batch Uploaded');
      })
      .then(() => setUploadingFileNames(null));
  };

  const handleDeleteStep = (id: string) => {
    dispatch(stepActions.remove(parseInt(id)));
    analytics.track('Step Deleted');
  };

  const handleDeleteClip = (id: string) => {
    // Remove the clip from the array of expanded clips
    setExpandedClipIds((prevState) =>
      prevState.filter((clip) => clip.id !== id)
    );
    // If the clip exists on the backend, delete it there too.
    //
    // We only submit the deletion request if the id can be
    // parsed to a number. If it can't, then it's likely a temporary
    // ID meaning the video clip does not exist in the backend.
    if (!isNaN(parseInt(id))) {
      dispatch(videoClipActions.remove(parseInt(id)));
      analytics.track('Clip Deleted');
    }
  };

  const handleCreateQuestion = async (
    { question, choices, choiceOrder, explanation }: MCQFormData,
    stepId: number
  ) =>
    await dispatch(
      stepQuestionActions.create({
        session: parseInt(sessionId),
        step: stepId,
        data: {
          content: question,
          answers: choices.map(({ text, isCorrect }) => ({
            content: text,
            correct: isCorrect,
          })),
          answerOrder: choiceOrder || ('random' as const),
          explanation,
        },
      })
    );

  const handleUpdateQuestion = async (
    { question, choices, choiceOrder, explanation }: MCQFormData,
    stepId: number,
    questionId: number
  ) =>
    await dispatch(
      stepQuestionActions.update({
        session: parseInt(sessionId),
        step: stepId,
        question: questionId,
        data: {
          content: question,
          answers: choices.map(({ text, isCorrect, id }) =>
            id
              ? {
                  id,
                  content: text,
                  correct: isCorrect,
                }
              : {
                  content: text,
                  correct: isCorrect,
                }
          ),
          answerOrder: choiceOrder || ('random' as const),
          explanation,
        },
      })
    );

  const handleSaveClipList = async (clips: VideoClipItem[]) => {
    // Only process clips which have a summary or a step ID set. If neither
    // of these values are set, then we don't need to make the API call.
    const dirtyClips = clips.filter(
      (clip) => Boolean(clip.stepId) || Boolean(clip.summary)
    );

    /** Compile a list of step ids that will need to be re-fetched */
    const stepIds = dirtyClips
      .map(({ stepId }) => stepId)
      .filter((value, index, self) => value && self.indexOf(value) === index);

    /**
     * Build a mapping of stepId -> array of clip ids
     * (video clipIds that we are about to assign to each step)
     * e.g : {1 : [1, 2, 3], 2: [4, 5] }
     */
    const stepClips: { [key: number]: number[] } = stepIds.reduce(
      (acc, stepId) =>
        stepId
          ? {
              ...acc,
              [stepId]: dirtyClips
                .filter(({ stepId: clipStepId }) => stepId === clipStepId)
                .map((clip) => clip.id),
            }
          : acc,
      {}
    );

    for (const dirtyClip of dirtyClips) {
      const { id, summary } = dirtyClip;
      // Always update the clip summary
      let clipData: Partial<IVideoClip> = { summary };

      // Attempt to find the relevant stepId using the stepClips object
      // --> this is the step that this clip will be assigned to
      const stepId = Object.keys(stepClips).find((stepId) =>
        stepClips[parseInt(stepId, 10)].find((clipId) => clipId === id)
      );

      if (stepId) {
        // If we found a stepId, find the current clipCount for that step
        // and use it to set the index for that clip
        // const step = steps[stepId];
        // const clipStepIdx = stepClips[parseInt(stepId, 10)].findIndex(
        //   (clipId) => clipId === id
        // );
        // const index = step.clipCount + clipStepIdx + 1;
        // clipData = { ...clipData, step: parseInt(stepId, 10), index };
        clipData = { ...clipData, step: parseInt(stepId, 10) };
        analytics.track('Clip Batch Step Allocated');
      }
      if (summary) {
        analytics.track('Clip Summary Edited');
      }
      await dispatch(videoClipActions.update(id, clipData));
    }

    // So that we have accurate and correct `clipCount` values, reload the
    // steps which have had video clips added to them
    Promise.all(
      stepIds.map((id) =>
        id ? dispatch(stepActions.retrieve(id)) : Promise.resolve(null)
      )
    );
  };

  const handleSavePrompt =
    (step: IStepListItem | undefined) =>
    async ({ label, title, responseType, tip }: PromptFormData) => {
      if (!step || !label || !title || !responseType || !tip) {
        return;
      }

      dispatch(
        stepActions.update(step.id, {
          prompt: { label, title, responseType, tip },
        })
      );
      analytics.track('Step Edited');
    };

  const handleDeletePrompt = (step: IStepListItem | undefined) => {
    if (!step) return;

    dispatch(
      stepActions.update(step.id, {
        prompt: undefined,
      })
    );
    analytics.track('Step Edited');
  };

  const handleSaveClip = async (id: string, data: Partial<IVideoClip>) => {
    if (data.videoBlob) {
      const videoFile = new File(
        [data.videoBlob],
        `session-${sessionId}-${CLIP_NAME}-${id}.mp4`,
        { type: data.videoBlob.type }
      );
      analytics.track('Clip Uploaded', {
        media_type: 'video',
        file_source: 'webcam_record',
      });
      return await handleAddMediaToClip(videoFile, id, 'video');
    }
    if (data.audioBlob) {
      const audioFile = new File(
        [data.audioBlob],
        `session-${sessionId}-${CLIP_NAME}-${id}.mp3`,
        { type: data.audioBlob.type }
      );
      analytics.track('Clip Uploaded', {
        media_type: 'audio',
        file_source: 'webcam_record',
      });
      return await handleAddMediaToClip(audioFile, id, 'audio');
    }
    if (data.image) {
      const imageFile = data.image as File;
      const imageUrl = URL.createObjectURL(imageFile);
      const dimensions = await videoUtils.getHeightAndWidthFromImageUrl(
        imageUrl
      );
      let orientation = 'portrait';
      if (dimensions.width > dimensions.height) {
        orientation = 'landscape';
      }
      const formData = new FormData();
      formData.append('image', imageFile);
      formData.append('orientation', orientation);
      formData.append('original_filename', imageFile.name);
      analytics.track('Clip Uploaded', {
        media_type: 'image',
        file_source: 'upload',
      });
      return await dispatch(videoClipActions.update(parseInt(id), formData));
    }
    if (data.subtitlesBlob) {
      const subtitlesFile = new File(
        [data.subtitlesBlob],
        `session-${sessionId}-${CLIP_NAME}-${id}.vtt`,
        { type: data.subtitlesBlob.type }
      );
      const formData = new FormData();
      formData.append('subtitles', subtitlesFile);
      analytics.track('Clip Subtitles Edited');
      return await dispatch(videoClipActions.update(parseInt(id), formData));
    }
    if (data.summary) {
      analytics.track('Clip Summary Edited');
    }
    if (data.script) {
      analytics.track('Clip Script Edited');
    }
    if (data.clipType) {
      analytics.track('Clip Media Type Selected', {
        media_type: data.clipType,
      });
    }
    return await dispatch(videoClipActions.update(parseInt(id), data));
  };

  // Collect all video & audio clips with media, that don't currently have a summary
  const noSummaryClips = Object.values(videoClips).filter(
    (c) =>
      ((c.clipType === 'video' && c.video) ||
        (c.clipType === 'audio' && c.audio)) &&
      !c.summary
  );

  const handleGenerateSummaries = async () => {
    const sortedNoSummaryClips = noSummaryClips.sort((a, b) => {
      if (a.step && b.step) {
        const aStep = steps[a.step];
        const bStep = steps[b.step];
        if (aStep && bStep) {
          return aStep.index - bStep.index || a.index - b.index;
        }
        return a.step - b.step || a.index - b.index;
      }
      return a.index - b.index;
    });
    setGeneratedSummaries(
      `Summaries Generated: 0 / ${sortedNoSummaryClips.length}`
    );
    analytics.track('Clip Summary Batch Generated');
    let idx = 1;
    // Generate summary for each clip
    for (const c of sortedNoSummaryClips) {
      const res = await dispatch(
        assistantActions.generateClipSummary('clip', `${c.id}`)
      );
      // TODO: Error handling
      if (res && res.error) {
        analytics.track('Clip Summary Failed');
        //   if (res.payload && 'normalizedErrors' in res.payload) {
        //     setErrorMessage(res.payload.normalizedErrors?.message as string);
        //   }
      } else {
        analytics.track('Clip Summary Generated');
      }
      setGeneratedSummaries(
        `Summaries Generated: ${idx} / ${sortedNoSummaryClips.length}`
      );
      idx += 1;
    }

    // Fetch updated summaries (unassigned and in steps)
    await dispatch(videoClipActions.list(parseInt(sessionId)));
    setGeneratedSummaries('Updating Summaries');
    await loadSteps();
    setGeneratedSummaries('');
  };

  const expandedStepQuestions = questions.filter((a) =>
    expandedStepData?.questions.find((id) => a.id === id)
  );

  // Progressive loading state used to only show UI as loading if elements are
  // loading for the first time.
  const isSessionLoading = sessionLoading && !session;
  const isStepListLoading = stepsLoading && !expandedStepData;
  const isClipListLoading =
    loadingSessionPlayer ||
    (videoClipUI.videoClipList.loading && isEmpty(expandedClipIds));

  const pageLoading =
    isSessionLoading || isStepListLoading || isClipListLoading;

  // Session Types
  const isIntroOrOutro = session
    ? session?.moduleType === SESSION_TYPE.intro ||
      session?.moduleType === SESSION_TYPE.outro
    : false;

  // Session Formats

  const isGuided =
    !selectedSessionFormat ||
    (selectedSessionFormat && selectedSessionFormat === SESSION_FORMAT.guided);

  const isPractice =
    selectedSessionFormat && selectedSessionFormat === SESSION_FORMAT.practice;

  const isLongform =
    selectedSessionFormat && selectedSessionFormat === SESSION_FORMAT.longform;

  const isResearch =
    selectedSessionFormat && selectedSessionFormat === SESSION_FORMAT.research;

  const isReflective =
    selectedSessionFormat && selectedSessionFormat === SESSION_FORMAT.reflect;

  const introStep = Object.values(steps).find(
    (step) => step.stepType === 'intro'
  );
  const introClips = Object.values(videoClips).filter(
    (c) => c.step === introStep?.id
  );
  const introClip = introClips.length > 0 ? introClips[0] : null;

  const outroStep = Object.values(steps).find(
    (step) => step.stepType === 'outro'
  );
  const outroClips = Object.values(videoClips).filter(
    (c) => c.step === outroStep?.id
  );
  const outroClip = outroClips.length > 0 ? outroClips[0] : null;

  const playerSteps = generatePlayerSteps({
    session,
    steps,
    questions: moduleQuestions,
    videoClips: Object.values(videoClips).map((clip) => {
      // If this clip was recently uploaded, replace the transcoded video
      // with the raw video in order to make the video available before
      // transcoding is complete
      const recentlyUploaded = !!Object.values(uploadList).find(
        ({ metadata }) =>
          metadata && 'id' in metadata && metadata.id === clip.id.toString()
      );
      return {
        ...clip,
        videoHls: recentlyUploaded ? clip.video : clip.videoHls,
        video720: recentlyUploaded ? clip.video : clip.video720,
      };
    }),
  });

  const playerChecklist = generatePlayerChecklist(session);

  const stepEditProps = {
    stepRegister: stepRegister,
    stepSetValue: stepSetValue,
    expandedStep: expandedStep,
    expandedStepData: expandedStepData,
    expandedClipData: expandedStepVideoClipData,
    clipData: expandedClipIds,
    // shouldShowQuestions:
    //   selectedSessionFormat ===
    //     SESSION_FORMAT.reflect && !isIntroOrOutro
    // ,
    shouldShowQuestions:
      !isIntroOrOutro &&
      expandedStepData?.stepType === SESSION_STEP_TYPE.normal,
    questions: expandedStepQuestions.sort((q1, q2) => q1.id - q2.id),
    onStepExpanded: onStepExpanded,
    onDragEnd: handleStepReorder,
    onClipDragEnd: handleClipReorder,
    handleAddStep: handleAddStep,
    handleUpdateStep: (stepId: string) =>
      stepHandleSubmit((data) => handleUpdateStep(stepId, data))(),
    handleCancelUpdateStep: () =>
      stepReset({
        supportingNotes: expandedStepData?.notes,
      }),
    handleCreateQuestion: handleCreateQuestion,
    handleUpdateQuestion: handleUpdateQuestion,
    handleDeleteStep: handleDeleteStep,
    handleAddClip: handleAddClip,
    handleAddMediaFromInput: handleAddMediaFromInput,
    handleDeleteClip: (id: string) => handleDeleteClip(id),
    handleSavePrompt: handleSavePrompt,
    handleDeletePrompt: handleDeletePrompt,
    onSaveClip: handleSaveClip,
    saveDisabled: Boolean(
      !stepFormstate.dirty &&
        Object.keys(stepErrors).length === 0 &&
        !clipFormState.dirty &&
        Object.keys(clipErrors).length === 0
    ),
    isDisabled: isEditingDisabled,
    isTextEditingDisabled: isTextEditingDisabled,
    isLoading: isStepListLoading,
    isStepUpdating: stepsUI.step.loading,
    isStepListUpdating: stepsUI.stepList.loading,
    isThumbnailUpdating: (id: string) => {
      const pollState = thumbnailPollState[id];
      // If a poll state exists and it's not complete then the
      // thumbnail is still updating
      return pollState ? !pollState.complete : false;
    },
    isClipLoading: isClipListLoading,
    // Intro & Outro steps should not be deletable, should not display notes
    // and should not display a title. Additionally, Practice/Research/Longform
    // session types only have a single step so should not be deletable either
    canDeleteStep: isIntroOrOutro
      ? expandedStepData?.index
        ? expandedStepData?.index > 1
        : false
      : isPractice || isResearch || isLongform
      ? false
      : true,
    // Longform sessions should have a single step with a single video clip.
    // Practice and Research sessions should have a single step with no video clips.
    // As such, we prevent the user from adding any clips or steps for these sessions.
    canAddStep: !isLongform && !isPractice && !isResearch,
    canAddClip: !isLongform && !isPractice && !isResearch,
    courseId: session?.course,
    sessionId: sessionId,
  };

  const pageTabs: InPageNavTab[] = [
    {
      slug: 'builder',
      label: 'Session Builder',
      icon: 'DashboardCustomize',
    },
    {
      slug: 'details',
      label: 'Edit Details',
      icon: 'EditNote',
    },
  ];

  const isNotReady =
    courseLoading || pageLoading
      ? undefined
      : !session?.image ||
        Object.values(steps).filter((s) => s.clipCount === 0).length > 0 ||
        Object.values(videoClips).filter(
          (c) =>
            !c.summary ||
            !c.clipType ||
            (c.clipType === 'image'
              ? !c.image
              : c.clipType === 'audio'
              ? !c.audio
              : c.clipType === 'video'
              ? !c.video
              : c.clipType === 'embed'
              ? !c.embed
              : c.clipType !== 'text')
        ).length > 0;
  // const isNotReady =
  //   courseLoading || isSessionLoading || !session
  //     ? undefined
  //     : !session.isReady;

  const prevIsNotReady = usePreviousValue(isNotReady);
  useEffect(() => {
    if (
      isNotReady === false &&
      !courseLoading &&
      !pageLoading &&
      prevIsNotReady === true
    ) {
      analytics.track('Session To Do List Completed');
    }
  }, [isNotReady, courseLoading, pageLoading, prevIsNotReady]);

  const toDoList = [
    {
      id: 0,
      content: 'Give your session an intro and outro',
      slug: '0',
      onClick: () => {
        analytics.track('Session To Do List Item Opened');
        setCurrentView('builder');
      },
      showClickable: currentView !== 'builder',
      totalNum: 2,
      currentNum: Math.min(
        Object.values(videoClips).filter(
          (c) =>
            (c.step === introStep?.id || c.step === outroStep?.id) &&
            !!c.summary &&
            (c.clipType === 'image'
              ? !!c.image
              : c.clipType === 'audio'
              ? !!c.audio
              : c.clipType === 'video'
              ? !!c.video
              : c.clipType === 'embed'
              ? !!c.embed
              : c.clipType === 'text')
        ).length,
        2
      ),
    },
    {
      id: 1,
      content: 'Upload a thumbnail image for the session',
      slug: '1',
      onClick: () => {
        analytics.track('Session To Do List Item Opened');
        setCurrentView('details');
      },
      showClickable: currentView !== 'details',
      totalNum: 1,
      currentNum: session?.image ? 1 : 0,
    },
    {
      id: 2,
      content: `Make sure each ${CLIP_NAME} has a written summary and media type`,
      slug: '2',
      onClick: () => {
        analytics.track('Session To Do List Item Opened');
        setCurrentView('builder');
      },
      showClickable: currentView !== 'builder',
      totalNum: Object.values(videoClips).length,
      currentNum: Object.values(videoClips).filter(
        (c) => !!c.summary && !!c.clipType
      ).length,
    },
    {
      id: 3,
      content: `Add relevant media to every ${CLIP_NAME} with a video, audio or picture type`,
      slug: '3',
      onClick: () => {
        analytics.track('Session To Do List Item Opened');
        setCurrentView('builder');
      },
      showClickable: currentView !== 'builder',
      totalNum: Object.values(videoClips).filter(
        (c) => c.clipType && c.clipType !== 'text'
      ).length,
      currentNum: Object.values(videoClips).filter(
        (c) =>
          c.clipType &&
          c.clipType !== 'text' &&
          (c.clipType === 'image'
            ? !!c.image
            : c.clipType === 'audio'
            ? !!c.audio
            : c.clipType === 'video'
            ? !!c.video
            : c.clipType === 'embed'
            ? !!c.embed
            : true)
      ).length,
    },
    {
      id: 4,
      content: 'Make sure you have no empty steps',
      slug: '4',
      onClick: () => {
        analytics.track('Session To Do List Item Opened');
        setCurrentView('builder');
      },
      showClickable: currentView !== 'builder',
      totalNum: Object.values(steps).length,
      currentNum: Object.values(steps).filter((s) => s.clipCount > 0).length,
    },
  ]
    .filter((i) => i.totalNum > 0)
    .map((i) => ({
      ...i,
      isChecked: i.currentNum >= i.totalNum,
      isComplete: i.currentNum >= i.totalNum,
      label: i.content,
    }));

  const numToDo = toDoList.filter((t) => !t.isChecked).length;

  if (isNotReady && numToDo > 0 && PLATFORM === 'workshop') {
    pageTabs.push({
      slug: 'todo',
      label: 'To Do',
      icon: 'TaskAlt',
      notification: numToDo,
    });
  }

  const isPro = Boolean(
    currentTeamProfile &&
      (currentTeamProfile.isPro || PRO_ORGS.includes(currentTeamProfile.id))
  );

  // Used for handling loading UI
  const sessionMightBeEmpty =
    isSessionLoading ||
    (isStepListLoading && session && session.stepCount <= 2);

  const sessionIsEmpty = Boolean(
    isBuilding ||
      Boolean(
        PLATFORM === 'steppit' &&
          !isSessionLoading &&
          !isStepListLoading &&
          !courseLoading &&
          Object.values(steps).filter((s) => s.stepType === 'normal').length ===
            0
      )
  );

  const overviewCardComponent = (
    <Flex flex={1}>
      <FormContext {...methods}>
        <OverviewCard
          onSave={handleSaveOverview}
          onCancel={handleCancel}
          landscape={session?.image}
          portrait={session?.imagePortrait}
          title={session?.title}
          isDisabled={isEditingDisabled}
          isUpdating={isUpdating.overview}
          isLoading={isSessionLoading}
          showImage={!sessionIsEmpty}
          // imageSize="sm"
          {...(sessionIsEmpty
            ? {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderWidth: 1,
              }
            : {})}
        >
          <OverviewTextArea
            id="title"
            name="title"
            label="Session title"
            labelPosition={isMobile || !sessionIsEmpty ? 'top' : 'inline'}
            labelStyleProps={{ fontSize: 'sm' }}
            defaultValue={session?.title}
            isDisabled={isEditingDisabled}
            isLoading={isSessionLoading}
            validation={{
              required: {
                value: true,
                message: 'Please enter a title.',
              },
            }}
            tooltip="session_title"
            fontWeight="semibold"
            autoResize
          />
          <OverviewTextArea
            id="description"
            name="description"
            label="Description"
            labelStyleProps={{ fontSize: 'sm' }}
            labelPosition={isMobile || !sessionIsEmpty ? 'top' : 'inline'}
            autoResize
            helpText=""
            placeholder="Write a brief description of your session"
            errorMessage="Please enter a description"
            defaultValue={session?.description}
            tooltip="session_summary"
          />
        </OverviewCard>
      </FormContext>
    </Flex>
  );

  if (sessionMightBeEmpty) {
    return (
      <ScreenWrapper>
        <Flex flex={1} alignItems="center" justifyContent="center">
          <Loading />
        </Flex>
      </ScreenWrapper>
    );
  }

  if (!sessionMightBeEmpty && !session && sessionIsEmpty) {
    return (
      <ScreenWrapper>
        <Card w="100%" mt={20}>
          <Flex
            py={4}
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            maxW="550px"
            mx="auto"
            mb={8}
          >
            <Flex
              boxSize="image.lg"
              alignItems="center"
              justifyContent="center"
              zIndex={1}
              borderRadius="full"
            >
              <Text fontSize="6xl">🔒</Text>
            </Flex>
            <Text
              fontWeight="extrabold"
              fontSize={{ base: '3xl', md: '4xl' }}
              lineHeight={1.2}
              mb={4}
            >
              Session Locked
            </Text>
            <Text fontSize="lg" color="text.muted" mb={8}>
              You do not have permission to edit this session. If you think this
              is incorrect, try switching to the correct channel below, or else
              please contact your channel owner or our support team.
            </Text>
            <LinkButton
              to={navRoutes.common.home.path()}
              icon="Home"
              size="lg"
              mb={2}
            >
              Open Home
            </LinkButton>
            <LinkButton href={contactUrl} icon="Help" variant="ghost">
              Contact Us
            </LinkButton>
          </Flex>
        </Card>
      </ScreenWrapper>
    );
  }

  const tourSteps = [
    {
      id: tourIds.sessionBuilderOverview,
      content: "Welcome to your session builder 👋 Let's take a quick tour...",
      placement: 'center',
      spotlightClicks: false,
    },
    {
      id: tourIds.sessionOutlineViewButton,
      content:
        "The Outline view is your starting point, providing a bird's-eye view of your session. This is best place to plan and outline the flow of your content.",
      spotlightClicks: false,
    },
    {
      id: tourIds.sessionOutlineSteps,
      content:
        'Your session consists of steps, as well as an intro to set the stage, and an outro to wrap things up.',
      nextCondition: Object.keys(expandedStepVideoClipData).length > 0,
      nextInstruction: 'Open Step 1 to continue',
    },
    {
      id: tourIds.sessionOutlineBit,
      content:
        'Each step is broken down into bits of content, which let you gradually guide your classes through key concepts and activities.',
      spotlightClicks: false,
    },
    {
      id: tourIds.sessionOutlineBitDrag,
      content:
        'Need to rearrange? You can drag your steps and bits around by grabbing their labels on the left.',
      spotlightClicks: false,
    },
    {
      id: tourIds.sessionStepperViewButton,
      content: "Now, let's explore the Stepper view.",
      nextCondition: tabIndex === 1,
      nextInstruction: 'Open Stepper view to continue',
    },
    {
      id: tourIds.sessionStepperView,
      content:
        'Here, you can focus on populating your session one step at a time, and get a feel for how it flows from start to finish.',
      spotlightClicks: false,
    },
    {
      id: tourIds.sessionStepperSteps,
      content:
        "Over here, you can pick a step to focus on. Let's jump into Step 1.",
      nextCondition: currentStepId === '1',
      nextInstruction: 'Open Step 1 to continue',
    },
    {
      id: tourIds.sessionStepperBits,
      content:
        'Within a step, you can use this panel to move between bits of content.',
      spotlightClicks: false,
    },
    {
      id: tourIds.sessionStepperMedia,
      content:
        "Media for your selected bit will show here. For engaging sessions, try adding videos to bits – it's a breeze with Steppit 👌.",
      spotlightClicks: false,
    },
    {
      id: tourIds.sessionChecklist,
      content:
        "That's it! You're well on your way to creating your first session. To get it all ready to go, just make sure to tick everything off this list ✅.",
      spotlightClicks: false,
    },
  ];

  const isStandalone = session?.isStandalone;
  const isOnlyStandalone = isStandalone && session?.syncedCourses?.length === 0;
  const isOnlyOnOneCourse =
    !isStandalone && session?.syncedCourses?.length === 1;
  const isLinkedToFromCourse =
    !!courseParam && Number(courseParam) === session?.course;
  const standaloneCourse = session?.standaloneCourseDetails;
  const isAvailableAsStandalone = !!standaloneCourse;
  const numSyncedCourses = session?.syncedCourses?.length;

  if (isOnlyStandalone) {
    tourSteps.push({
      id: tourIds.sessionPublishButton,
      content:
        "Only you can view your session right now – when you're done, make sure to share it with the world 🌎",
      spotlightClicks: false,
    });
  }

  return (
    <>
      <ScreenWrapper>
        {numSyncedCourses > 1 && session && (
          <SyncedSessionModal
            isOpen={showSyncedModal}
            onClose={() => setShowSyncedModal(false)}
            state={syncedModalState}
            updateState={(syncedState: 'info' | 'copy') =>
              setSyncedModalState(syncedState)
            }
            sessionName={session.title}
            sessionId={session.id}
            isLoading={isSessionLoading}
            courseOptions={session.syncedCourses || []}
            initialCourseId={isLinkedToFromCourse ? session.course : undefined}
          />
        )}
        {currentTeamProfile &&
          standaloneCourse?.status === 'published' &&
          PLATFORM === 'steppit' && (
            <ShareSessionModal
              isOpen={showShareModal}
              onClose={() => setShowShareModal(false)}
              courseSlug={standaloneCourse.slug}
              channelHandle={currentTeamProfile.handle}
              isPublic={standaloneCourse.isPublic}
              isLoading={isUpdating.publishStatus}
              setIsPublic={async (isPublic) => {
                setIsUpdating({ ...isUpdating, publishStatus: true });
                await dispatch(
                  courseActions.update(standaloneCourse.id, {
                    isPublic,
                  })
                );
                setIsUpdating({ ...isUpdating, publishStatus: false });
              }}
              isVisible={standaloneCourse.isVisible}
              setIsVisible={async (isVisible) => {
                setIsUpdating({ ...isUpdating, publishStatus: true });
                await dispatch(
                  courseActions.update(standaloneCourse.id, {
                    isVisible,
                  })
                );
                setIsUpdating({ ...isUpdating, publishStatus: false });
              }}
            />
          )}
        <Flex flexDirection="column" flex={1} mb={24}>
          {session && (isLinkedToFromCourse || isOnlyOnOneCourse) && (
            <Flex
              zIndex={4}
              mt={-5}
              mb={5}
              mx={{ base: 'defaultMargin', md: 0 }}
              display={planSlug ? 'none' : 'flex'}
            >
              <LinkButton
                to={navRoutes.cms.editCourse.path(session.course)}
                size="sm"
                icon="ArrowBack"
                variant="outline"
              >
                Back to Course
              </LinkButton>
            </Flex>
          )}
          {session && numSyncedCourses > 1 && (
            <Box
              zIndex={4}
              mx={{ base: 'defaultMargin', md: 0 }}
              mt={!isLinkedToFromCourse && !isOnlyOnOneCourse ? -4 : 0}
            >
              <InformationCard
                id="synced-session"
                information={{
                  description: `This session appears in ${numSyncedCourses} courses. Edits made here will be visible on ${
                    numSyncedCourses === 2 ? 'both' : 'all'
                  } of them.`,
                  icon: (
                    <Flex
                      w={5}
                      h={6}
                      alignItems="center"
                      mr={3}
                      color="blue.500"
                      _dark={{ color: 'blue.300' }}
                    >
                      <MdIcon name="Sync" boxSize={5} />
                    </Flex>
                  ),
                }}
                isDismissable={false}
                mb={6}
                cta={
                  <Button
                    variant="ghost"
                    w={8}
                    size="sm"
                    icon="InfoOutline"
                    onClick={() => {
                      setSyncedModalState('info');
                      setShowSyncedModal(true);
                    }}
                  />
                }
              />
            </Box>
          )}
          {!sessionIsEmpty && (
            <InPageNav
              tabs={pageTabs}
              initialTab="builder"
              currentTab={currentView}
              onSwitchTab={(activeTab) => setCurrentView(activeTab)}
              disabled={pageLoading}
              navByParams
              rightElement={
                pageLoading || courseLoading ? undefined : PLATFORM ===
                  'steppit' ? (
                  <>
                    {isAvailableAsStandalone &&
                    standaloneCourse?.status === 'published' ? (
                      <Button
                        colorScheme="green"
                        variant="outline"
                        size="sm"
                        icon="Share"
                        onClick={() => setShowShareModal(true)}
                        isLoading={isUpdating.publishStatus}
                      >
                        <Text>Share</Text>
                      </Button>
                    ) : isNotReady ? (
                      <Text color="text.error" fontSize="sm" pl={2}>
                        Not Ready
                      </Text>
                    ) : (
                      <Flex position="relative">
                        <Button
                          colorScheme="green"
                          size="sm"
                          variant="outline"
                          icon="Share"
                          isDisabled={numToDo > 0 || isRewardAnimating}
                          onClick={async () => {
                            // PLATFORM === 'steppit' && reward();
                            setIsUpdating({
                              ...isUpdating,
                              publishStatus: true,
                            });
                            let courseIdToShare = standaloneCourse?.id;
                            // Create standalone course if not already available
                            if (!isAvailableAsStandalone) {
                              // Create the course
                              const newCourseRes = await dispatch(
                                courseActions.create({
                                  title: session.title,
                                  courseType: 'session',
                                })
                              );
                              // @ts-ignore
                              if (newCourseRes?.payload?.entities?.courses) {
                                const newCourse =
                                  // @ts-ignore
                                  newCourseRes.payload.entities.courses[
                                    // @ts-ignore
                                    newCourseRes.payload.result
                                  ];
                                courseIdToShare = newCourse.id;
                                // Grab the new standalone session
                                const newSessionRes = await dispatch(
                                  sessionActions.retrieve(
                                    newCourse.standaloneModule
                                  )
                                );
                                // @ts-ignore
                                if (newSessionRes?.payload?.entities?.session) {
                                  const newSession =
                                    // @ts-ignore
                                    newSessionRes.payload.entities.session[
                                      // @ts-ignore
                                      newSessionRes.payload.result
                                    ];
                                  // Duplicate this session to the newly created course
                                  await dispatch(
                                    sessionActions.duplicate(
                                      session.id,
                                      newSession.unit
                                    )
                                  );
                                  // Delete the newly created session to replace it
                                  await dispatch(
                                    sessionActions.remove(newSession.id)
                                  );
                                }
                                // Refresh state
                                await Promise.all([
                                  dispatch(sessionActions.retrieve(session.id)),
                                  dispatch(
                                    courseActions.retrieve(newCourse.id)
                                  ),
                                ]);
                              }
                            }
                            if (!courseIdToShare) {
                              setIsUpdating({
                                ...isUpdating,
                                publishStatus: false,
                              });
                              return;
                            }
                            await dispatch(
                              courseActions.update(courseIdToShare, {
                                status: 'published',
                                isPublic: false,
                              })
                            );
                            setIsUpdating({
                              ...isUpdating,
                              publishStatus: false,
                            });
                            setShowShareModal(true);
                          }}
                          isLoading={isUpdating.publishStatus}
                          data-tour={tourIds.sessionPublishButton}
                          id="publish"
                        >
                          <Text>Share</Text>
                        </Button>
                        {numToDo > 0 && (
                          <Tooltip
                            label="Take a look at your to-do list to see what's remaining before you can publish your session"
                            placement="left"
                          >
                            <Box
                              position="absolute"
                              top={0}
                              right={0}
                              bottom={0}
                              left={0}
                              // onClick={() => setCurrentView('todo')}
                              cursor="not-allowed"
                            />
                          </Tooltip>
                        )}
                      </Flex>
                    )}
                  </>
                ) : (
                  <Text
                    color={isNotReady ? 'text.error' : 'text.success'}
                    fontSize="sm"
                    pl={2}
                  >
                    {isNotReady ? 'Not Ready' : 'Ready'}
                  </Text>
                )
              }
            />
          )}

          {currentView === 'details' && (
            <>
              <Box mx={{ base: 'defaultMargin', md: 0 }}>
                <InformationCard id="session_details" mb={6} />
              </Box>
              <Flex flexDirection={{ base: 'column' }} mb={{ base: 0 }}>
                <Flex flexDirection="column" mb="defaultMargin">
                  <SectionTitle title="Overview" />
                  {overviewCardComponent}
                </Flex>
                {!isIntroOrOutro && (
                  <Flex
                    flexDirection="column"
                    flex={1}
                    marginLeft={{ base: 0 }}
                    marginY={{ base: 'defaultMargin' }}
                  >
                    <SectionTitle title="Details" />
                    <FurtherDetailsSessionCard
                      moduleFormatOptions={sessionFormatOptions}
                      moduleFormat={session?.moduleFormat}
                      onSave={handleSaveFurtherDetails}
                      onCancel={handleCancel}
                      sessionDuration={session?.duration}
                      requiresUpload={session?.requiresUpload}
                      isDisabled={isEditingDisabled}
                      isUpdating={isUpdating.furtherDetails}
                      isLoading={isSessionLoading}
                      setModuleFormat={setSessionFormat}
                    />
                  </Flex>
                )}
              </Flex>
              {/* {!isIntroOrOutro && (
                <Flex flexDirection="column" marginY="defaultMargin">
                  <SectionTitle title="Description" />
                  <FormCard
                    onSave={handleSaveDescription}
                    isDisabled={isEditingDisabled}
                    isUpdating={isUpdating.description}
                    isLoading={isSessionLoading}
                    items={[
                      {
                        id: 'description',
                        name: 'description',
                        label: '',
                        helpText: '',
                        errorMessage: 'Please enter a description',
                        defaultValue: session?.description,
                        tooltip: 'session_summary',
                      },
                    ]}
                  />
                </Flex>
              )} */}
              {/* We don't show requirements for Longform (Extended) Sessions or Intro/Outro sessions */}
              {!isLongform && !isIntroOrOutro && (
                <Flex flexDirection="column" marginY="defaultMargin">
                  <SectionTitle title="Checklist" />
                  <Text
                    color="text.muted"
                    fontSize="sm"
                    mb={4}
                    mx={{ base: 'defaultMargin', md: 0 }}
                  >
                    This is the list of items learners should have ready for
                    when they start your session. When they start the session,
                    they can check each item off the list.
                  </Text>
                  <Requirement
                    onSave={handleSaveRequirement}
                    isDisabled={isEditingDisabled}
                    isUpdating={isUpdating.requirements}
                    isLoading={isSessionLoading}
                    name="name"
                    requirements={session?.checkList[0]}
                  />
                </Flex>
              )}
            </>
          )}
          {currentView === 'builder' && !sessionIsEmpty && (
            <Box mx={{ base: 'defaultMargin', md: 0 }}>
              <InformationCard id="session_builder" mb={6} />
            </Box>
          )}
          {currentView === 'builder' && sessionIsEmpty && (
            <Flex mb={12} flexDirection="column">
              <Flex mb={6} display={planSlug ? 'none' : 'flex'}>
                {overviewCardComponent}
              </Flex>
              <SessionManualPlan
                courseId={`${session.course}`}
                unitId={`${session.unit}`}
                sessionId={sessionId}
                handleAddStep={handleAddStep}
                handleAddClip={handleAddClip}
                handleSaveClip={handleSaveClip}
                setIsBuilding={setIsBuilding}
                isBuilding={isBuilding}
                introClipId={`${introClip?.id}`}
                outroClipId={`${outroClip?.id}`}
                planSlug={planSlug}
              />
            </Flex>
          )}
          {currentView === 'builder' && !sessionIsEmpty && (
            <Flex flexDir="column" position="relative">
              <Tabs
                index={tabIndex}
                onChange={(index) => {
                  setTabIndex(index);
                }}
                isLazy
                variant="unstyled"
                data-tour={tourIds.sessionBuilderOverview}
                mt={2}
              >
                <Flex
                  alignItems="center"
                  mb={6}
                  mx={{ base: 'defaultMargin', md: 0 }}
                >
                  <TabList flex={1} pr={4}>
                    <Tab
                      px={{ base: 3, sm: 4 }}
                      py={2}
                      mr={{ base: 1, sm: 2 }}
                      fontSize={{ base: 'sm', md: 'md' }}
                      borderRadius="full"
                      transition="background-color 0.3s, color 0.3s"
                      color="text.muted"
                      _hover={{
                        bg: 'background.primary',
                      }}
                      _active={{
                        bg: 'background.primaryDark',
                      }}
                      _selected={{
                        color: 'text.primaryDark',
                        bg: 'background.primaryDark',
                      }}
                      data-tour={tourIds.sessionOutlineViewButton}
                    >
                      <MdIcon name="ViewList" />
                      <Text ml={1.5} fontWeight="semibold">
                        Outline
                        <chakra.span display={{ base: 'none', md: 'inline' }}>
                          {' View'}
                        </chakra.span>
                      </Text>
                    </Tab>
                    <Tab
                      px={{ base: 3, sm: 4 }}
                      py={2}
                      fontSize={{ base: 'sm', md: 'md' }}
                      borderRadius="full"
                      transition="background-color 0.3s, color 0.3s"
                      color="text.muted"
                      _hover={{
                        bg: 'background.primary',
                      }}
                      _active={{
                        bg: 'background.primaryDark',
                      }}
                      _selected={{
                        color: 'text.primaryDark',
                        bg: 'background.primaryDark',
                      }}
                      data-tour={tourIds.sessionStepperViewButton}
                    >
                      <MdIcon name="ViewCarousel" />
                      <Text ml={1.5} fontWeight="semibold">
                        Stepper
                        <chakra.span display={{ base: 'none', md: 'inline' }}>
                          {' View'}
                        </chakra.span>
                      </Text>
                    </Tab>
                  </TabList>
                  <Flex alignItems="center">
                    {pageLoading && (
                      <Flex pl={4}>
                        <Spinner size="md" color="text.muted" />
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                <TabPanels>
                  <TabPanel padding={0}>
                    {/* If the user has permission to edit clip summaries, show the following card */}
                    {hasSummaryEditPermissions && !hasEditPermissions && (
                      <Box
                        marginY="defaultMargin"
                        flex={1}
                        mx={{ base: 'defaultMargin', md: 0 }}
                      >
                        <InformationCard id="copywriter_overview" />
                      </Box>
                    )}
                    {/* TODO: Deprecate */}
                    {/* We only show Introduction & Summary Steps for Reflective sessions */}
                    {isReflective && (
                      <Flex flexDirection="column" marginY="defaultMargin">
                        <SectionTitle title="Intro & Outro" />
                        {isReflective ? (
                          <Card direction="column" padding={0}>
                            <PromptItem
                              key="prompt-item-1"
                              id="prompt-item-1"
                              title="Intro"
                              label="Intro"
                              defaultFormValues={introStep?.prompt || {}}
                              disableCancel={Boolean(introStep?.prompt)}
                              showPrompt={Boolean(introStep?.prompt)}
                              onClick={() => {}}
                              isDisabled={isEditingDisabled}
                              isLoading={isSessionLoading || isStepListLoading}
                              complete={!!intro}
                              helpText="Recommended: Ask the learner to reflect on a “Key Question” before starting this session."
                              onSubmit={handleSavePrompt(introStep)}
                              handleDeletePrompt={() =>
                                handleDeletePrompt(introStep)
                              }
                            />
                            <PromptItem
                              key={2}
                              id="prompt-item-2"
                              title="Outro"
                              label="Outro"
                              defaultFormValues={outroStep?.prompt || {}}
                              disableCancel={Boolean(outroStep?.prompt)}
                              showPrompt={Boolean(outroStep?.prompt)}
                              onClick={() => {}}
                              isDisabled={isEditingDisabled}
                              isLoading={isSessionLoading || isStepListLoading}
                              complete={!!outro}
                              helpText="Recommended: Ask the learner to submit a response to a “Discussion Point” after completing this session."
                              onSubmit={handleSavePrompt(outroStep)}
                              handleDeletePrompt={() =>
                                handleDeletePrompt(outroStep)
                              }
                            />
                          </Card>
                        ) : null}
                        {/* //   <FormContext {...videoClipMethods}>
                        //     <StepEdit
                        //       stepRegister={stepRegister}
                        //       stepSetValue={stepSetValue}
                        //       expandedStep={expandedStep}
                        //       expandedStepData={expandedStepData}
                        //       expandedClipData={expandedStepVideoClipData}
                        //       stepData={[...introSteps, ...outroSteps]}
                        //       clipData={expandedClipIds}
                        //       shouldShowQuestions={false}
                        //       questions={expandedStepQuestions.sort(
                        //         (q1, q2) => q1.id - q2.id
                        //       )}
                        //       onStepExpanded={onStepExpanded}
                        //       onDragEnd={handleStepReorder}
                        //       onClipDragEnd={handleClipReorder}
                        //       handleAddStep={handleAddStep}
                        //       handleUpdateStep={(stepId) =>
                        //         stepHandleSubmit((data) =>
                        //           handleUpdateStep(stepId, data)
                        //         )()
                        //       }
                        //       handleCancelUpdateStep={() =>
                        //         stepReset({
                        //           supportingNotes: expandedStepData?.notes,
                        //         })
                        //       }
                        //       handleCreateQuestion={handleCreateQuestion}
                        //       handleUpdateQuestion={handleUpdateQuestion}
                        //       handleDeleteStep={handleDeleteStep}
                        //       handleAddClip={handleAddClip}
                        //       handleAddMediaFromInput={handleAddMediaFromInput}
                        //       handleDeleteClip={(id) => handleDeleteClip(id)}
                        //       onSaveClip={handleSaveClip}
                        //       saveDisabled={Boolean(
                        //         !stepFormstate.dirty &&
                        //           Object.keys(stepErrors).length === 0 &&
                        //           !clipFormState.dirty &&
                        //           Object.keys(clipErrors).length === 0
                        //       )}
                        //       isDisabled={isEditingDisabled}
                        //       isTextEditingDisabled={isTextEditingDisabled}
                        //       isLoading={isStepListLoading}
                        //       isStepUpdating={stepsUI.step.loading}
                        //       isStepListUpdating={stepsUI.stepList.loading}
                        //       isThumbnailUpdating={(id) => {
                        //         const pollState = thumbnailPollState[id];
                        //         // If a poll state exists and it's not complete then the
                        //         // thumbnail is still updating
                        //         return pollState ? !pollState.complete : false;
                        //       }}
                        //       isClipLoading={isClipListLoading}
                        //       isDraggable={false}
                        //       canAddStep={false}
                        //       // If the clip is an intro or outro we hide the option to
                        //       // delete first video to ensure there is always at least one clip
                        //       canDeleteClip={
                        //         !isEditingDisabled &&
                        //         isIntroOrOutro &&
                        //         Object.keys(expandedStepVideoClipData).length <=
                        //           1
                        //       }
                        //       // Intro & Outro steps should not be deletable, should not display notes
                        //       // and should not display a title
                        //       canDeleteStep={false}
                        //       shouldShowNotes={false}
                        //     />
                        //   </FormContext>
                        // )} */}
                      </Flex>
                    )}
                    {isLongform && (
                      <Box
                        marginY="defaultMargin"
                        flex={1}
                        mx={{ base: 'defaultMargin', md: 0 }}
                      >
                        <InformationCard id="extended_session_step" />
                      </Box>
                    )}
                    {!isPractice && !isResearch ? (
                      <Flex
                        flexDirection="column"
                        marginY="defaultMargin"
                        position="relative"
                      >
                        {isReflective && <SectionTitle title="Steps" />}
                        <FormContext {...videoClipMethods}>
                          {isGuided && (
                            <Box mb="defaultMargin">
                              <StepEdit
                                {...stepEditProps}
                                stepData={introSteps}
                                isDraggable={false}
                                canAddStep={false}
                                canDeleteStep={false}
                                shouldShowNotes={false}
                                canAddClip={false}
                                canDeleteClip={
                                  !isEditingDisabled &&
                                  isIntroOrOutro &&
                                  Object.keys(expandedStepVideoClipData)
                                    .length <= 1
                                }
                              />
                            </Box>
                          )}
                          <Box>
                            <StepEdit
                              {...stepEditProps}
                              stepData={orderBy(normalSteps, 'index')}
                            />
                          </Box>
                          {isGuided && (
                            <Box mt="defaultMargin">
                              <StepEdit
                                {...stepEditProps}
                                stepData={outroSteps}
                                isDraggable={false}
                                canAddStep={false}
                                canDeleteStep={false}
                                shouldShowNotes={false}
                                canAddClip={false}
                                canDeleteClip={
                                  !isEditingDisabled &&
                                  isIntroOrOutro &&
                                  Object.keys(expandedStepVideoClipData)
                                    .length <= 1
                                }
                              />
                            </Box>
                          )}
                        </FormContext>
                        {!isEditingDisabled && (
                          <>
                            <Flex
                              my={4}
                              flexDirection={{ base: 'column', sm: 'row' }}
                              alignItems={{ base: 'flex-end', sm: 'normal' }}
                              justifyContent={{
                                base: 'normal',
                                sm: 'flex-end',
                              }}
                            >
                              {(PLATFORM === 'steppit' ||
                                (PLATFORM === 'workshop' && hasAdminRole)) && (
                                <>
                                  {/* TODO: Only show if ungenerated subtitles available, otherwise show cc(tick)  */}
                                  {/* <Button
                                    icon="ClosedCaption"
                                    variant="outline"
                                    size="sm"
                                    colorScheme="orange"
                                    onClick={() => {
                                      if (isPro) {
                                        // TODO: Generate captions for all clips in a given session
                                      } else {
                                        setTriggerOpenProPopup(
                                          !triggerOpenProPopup
                                        );
                                      }
                                    }}
                                    display={{ base: 'none', md: 'flex' }}
                                    mr={2}
                                  >
                                    Generate Captions
                                  </Button> */}
                                  <Flex
                                    position="relative"
                                    mb={{ base: 2, sm: 0 }}
                                    mr={{ base: 'defaultMargin', sm: 2 }}
                                  >
                                    <Button
                                      icon="AutoAwesome"
                                      variant="outline"
                                      size="sm"
                                      colorScheme="orange"
                                      onClick={() => {
                                        if (isPro) {
                                          setShowSummaryConfirm(true);
                                        } else {
                                          setTriggerOpenProPopup(
                                            !triggerOpenProPopup
                                          );
                                        }
                                      }}
                                      isLoading={!!generatedSummaries}
                                      loadingText={generatedSummaries}
                                      isDisabled={noSummaryClips.length === 0}
                                    >
                                      <chakra.span>
                                        Auto-fill
                                        <chakra.span
                                          display={{
                                            base: 'none',
                                            md: 'inline',
                                          }}
                                        >
                                          {' Summaries'}
                                        </chakra.span>
                                      </chakra.span>
                                    </Button>
                                    {noSummaryClips.length === 0 && (
                                      <Tooltip
                                        label={`This session currently has no video or audio ${CLIP_NAME}s without a summary`}
                                        placement="left"
                                      >
                                        <Box
                                          position="absolute"
                                          top={0}
                                          right={0}
                                          bottom={0}
                                          left={0}
                                          cursor="not-allowed"
                                        />
                                      </Tooltip>
                                    )}
                                  </Flex>
                                </>
                              )}
                              {!mutliVideoUpload.visible ? (
                                <Button
                                  icon="CloudUpload"
                                  variant="outline"
                                  size="sm"
                                  onClick={() =>
                                    setMultiVideoUpload({
                                      visible: true,
                                      error: null,
                                    })
                                  }
                                  mr={{ base: 'defaultMargin', md: 0 }}
                                >
                                  <chakra.span>
                                    Batch Upload
                                    <chakra.span
                                      display={{ base: 'none', md: 'inline' }}
                                    >
                                      {' Clips'}
                                    </chakra.span>
                                  </chakra.span>
                                </Button>
                              ) : (
                                <Button
                                  icon="Close"
                                  secondary
                                  size="sm"
                                  onClick={() =>
                                    setMultiVideoUpload({
                                      visible: false,
                                      error: null,
                                    })
                                  }
                                  px={2}
                                />
                              )}
                            </Flex>
                            {generatedSummaries ? (
                              <Flex
                                alignItems="center"
                                justifyContent="flex-end"
                                mr={{ base: 'defaultMargin', md: 0 }}
                              >
                                <Spinner
                                  size="xs"
                                  color="common.primary"
                                  mr={2}
                                />
                                <Text color="text.muted" fontSize="sm">
                                  Please keep this window open while summaries
                                  are generated...
                                </Text>
                              </Flex>
                            ) : null}
                          </>
                        )}
                        {isEditingDisabled
                          ? null
                          : mutliVideoUpload.visible && (
                              <Card flexDir="column" mb={2}>
                                <VideoClipsUpload
                                  onUploadClips={(clips) =>
                                    handleMultipleClipUpload(clips)
                                      .then(() => {
                                        setMultiVideoUpload({
                                          visible: false,
                                          error: null,
                                        });
                                        return true;
                                      })
                                      .catch(() => {
                                        setMultiVideoUpload({
                                          visible: true,
                                          error:
                                            'The upload failed for the above clips. Please refresh the page and try again.',
                                        });
                                        return false;
                                      })
                                  }
                                  error={mutliVideoUpload.error}
                                  onClearError={() =>
                                    setMultiVideoUpload((state) => ({
                                      ...state,
                                      error: null,
                                    }))
                                  }
                                />
                              </Card>
                            )}
                        {unassignedVideoClips.length ? (
                          <>
                            {/* Don't display the card about assigning clips if editing is disabled */}
                            {!isEditingDisabled && (
                              <Flex
                                mx={{ base: 'defaultMargin', md: 0 }}
                                marginBottom="defaultMargin"
                              >
                                <InformationCard id="unassigned_clips" />
                              </Flex>
                            )}
                            <Card
                              flexDir="column"
                              mb="defaultMargin"
                              overflow="visible"
                            >
                              <VideoClipsList
                                availableSteps={normalSteps}
                                uploadInProgress={pollingInProgress}
                                videoClips={unassignedVideoClips.map(
                                  ({
                                    id,
                                    orientation,
                                    video,
                                    video720,
                                    audio,
                                    imageMobile,
                                    originalFilename: name,
                                    videoThumbnail,
                                    summary,
                                    script,
                                    clipType,
                                    embed,
                                  }) => ({
                                    id,
                                    name: name || '',
                                    orientation,
                                    videoSrc:
                                      clipType === 'audio'
                                        ? audio
                                        : clipType === 'image'
                                        ? imageMobile
                                        : clipType === 'embed'
                                        ? embed
                                        : video720 || video,
                                    image:
                                      clipType === 'audio' ||
                                      clipType === 'embed' ||
                                      clipType === 'text'
                                        ? ''
                                        : clipType === 'image'
                                        ? imageMobile
                                        : videoThumbnail,
                                    summary,
                                    script,
                                    mediaType: clipType,
                                  })
                                )}
                                isDisabled={isEditingDisabled}
                                isTextEditingDisabled={isTextEditingDisabled}
                                onDelete={async (id) => {
                                  await dispatch(videoClipActions.remove(id));
                                  analytics.track('Clip Deleted');
                                }}
                                onSave={handleSaveClipList}
                              />
                            </Card>
                          </>
                        ) : null}
                      </Flex>
                    ) : (
                      <Flex flexDirection="column" marginY="defaultMargin">
                        <SectionTitle title="Exercises & Notes" />
                        <FormCard
                          onSave={handleSaveExercises}
                          isDisabled={!isEditingDisabled}
                          isUpdating={isUpdating.exercises}
                          isLoading={isSessionLoading}
                          items={[
                            {
                              id: 'exercise',
                              name: 'exercise',
                              label: 'Exercises',
                              helpText: '',
                              errorMessage: 'Please enter an exercise',
                              defaultValue: session?.exerciseText,
                            },
                            {
                              id: 'notes',
                              name: 'notes',
                              label: 'Notes',
                              helpText: '',
                              errorMessage: 'Please enter a note',
                            },
                          ]}
                        />
                      </Flex>
                    )}
                  </TabPanel>
                  <TabPanel padding={0} data-tour={tourIds.sessionStepperView}>
                    <SessionPlayer
                      loading={loadingSessionPlayer || pageLoading}
                      onCompleteSession={async () => null}
                      onSetOrienation={async () => null}
                      onUnlockStep={async () => null}
                      requirements={playerChecklist}
                      showRequirements={Boolean(playerChecklist)}
                      steps={playerSteps}
                      previewModeEnabled
                      isEditable={!isEditingDisabled}
                      onSaveClip={handleSaveClip}
                      handleVideoUpload={handleAddMediaFromInput}
                      handleAddClip={handleAddClip}
                      handleAddStep={(data) => handleAddStep(data, true)}
                      // handleUpdateStep={(stepId, title) =>
                      //   stepHandleSubmit((data) =>
                      //     handleUpdateStep(stepId, { ...data, title })
                      //   )()
                      // }
                      pathname={location.pathname}
                      navigationStep={currentStepId}
                      navigateToStep={(
                        idx: number,
                        endOfStep: boolean = false
                      ) => {
                        let searchParams = new URLSearchParams();
                        searchParams.set('step', idx.toString());
                        if (endOfStep) {
                          searchParams.set('e', '1');
                        } else {
                          searchParams.delete('e');
                        }
                        history.push({
                          pathname: location.pathname,
                          search: searchParams.toString(),
                        });
                      }}
                    />
                    <Flex
                      mt={6}
                      mb={6}
                      mx={{ base: 'defaultMargin', md: 0 }}
                      borderRadius="md"
                    >
                      <Text fontSize="sm" color="text.muted">
                        <b>Note:</b> To re-order or delete content, upload in
                        batch or add notes and interactive elements to your
                        steps, switch over to{' '}
                        <chakra.span
                          color="text.primary"
                          cursor="pointer"
                          _hover={{ textDecoration: 'underline' }}
                          onClick={() => setTabIndex(0)}
                          fontWeight="semibold"
                        >
                          Outline View
                        </chakra.span>
                        .
                      </Text>
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
              <Box height={0}>
                <ProCta
                  hideCta
                  hideProIcon
                  label=""
                  triggerOpenPopup={triggerOpenProPopup}
                />
              </Box>
              <ConfirmModal
                body="Do you want to ask your AI-powered assistant to auto-fill summaries wherever possible?"
                helpText={`This will only generate summaries for ${CLIP_NAME}s that currently have video or audio media, but no existing summary.`}
                btnColor="green"
                btnLabel="Yes, Generate"
                title={`Auto-fill ${noSummaryClips.length} Summaries`}
                isLoading={!!generatedSummaries}
                isOpen={showSummaryConfirm}
                onClose={() => setShowSummaryConfirm(false)}
                onClick={() => handleGenerateSummaries()}
              />
            </Flex>
          )}
          {currentView === 'builder' &&
            !sessionIsEmpty &&
            isNotReady === true &&
            session &&
            PLATFORM === 'steppit' && (
              <Stack
                w="100%"
                marginBottom="defaultMargin"
                data-tour={tourIds.sessionChecklist}
              >
                <OnboardingChecklist
                  id={`session-${session.id}-onboarding`}
                  onboardingSteps={toDoList}
                  title="Create Your Session"
                  emoji="💡"
                  ownerOnly={false}
                  canBeDismissed={false}
                  alwaysFullWidth
                />
              </Stack>
            )}
          {currentView === 'todo' && (
            <Flex flexDirection="column">
              <Box flex={1} mx={{ base: 'defaultMargin', md: 0 }}>
                <InformationCard id="session_to_do" mb={6} />
              </Box>
              <Card padding={4} flexDirection="column">
                <Text mb={3} color="text.muted">
                  Here's a list of what you need to do to get your session ready
                  for publishing:
                </Text>
                <CheckList
                  items={toDoList.sort(
                    (a, b) => Number(a.isChecked) - Number(b.isChecked)
                  )}
                  checkable={false}
                />
              </Card>
              {/* TODO: Things to think about? Prompts, Qs, intros/outros, checklist */}
            </Flex>
          )}
        </Flex>
      </ScreenWrapper>

      {isEditingDisabled && (
        <FixedFooter
          text={
            isLockedForEditing
              ? 'This session is locked for editing.'
              : hasSummaryEditPermissions
              ? 'You have limited permissions to make changes'
              : 'You do not have the required permissions to make changes'
          }
        />
      )}
      {PLATFORM === 'steppit' &&
        !courseLoading &&
        !pageLoading &&
        !sessionIsEmpty && (
          <Tour
            showSkipButton
            hideBackButton
            scrollOffset={100}
            analyticsName="Session Builder"
            steps={tourSteps}
            hideIndicators
            onDismiss={() => setTabIndex(0)}
            // steps={[
            //   {
            //     id: tourIds.sessionBuilder,
            //     content:
            //       "This is your session builder, where you'll create your session step by step, bit by bit.",
            //     placement: 'center',
            //     spotlightClicks: false,
            //   },
            //   {
            //     id: tourIds.sessionMediaType,
            //     content:
            //       'Begin by introducing who you are and what this session is about. For the best warm welcome to your learners, we recommend going with a video media type for this first bit.',
            //     nextCondition: !!playerSteps.find(
            //       (s) =>
            //         s.stepType === 'intro' &&
            //         s.subSteps.length > 0 &&
            //         s.subSteps[0].clipMediaType === 'video'
            //     ),
            //     nextInstruction: 'Click the video media type to continue',
            //   },
            //   {
            //     id: tourIds.sessionIntroRecord,
            //     content:
            //       'Good stuff! Record a quick intro using your web cam, or upload a video you made earlier. (You can change this later!)',
            //     nextCondition: !!playerSteps.find(
            //       (s) =>
            //         s.stepType === 'intro' &&
            //         s.subSteps.length > 0 &&
            //         !!s.subSteps[0].clipSrc
            //     ),
            //     nextInstruction: 'Record or upload a video to continue',
            //   },
            //   {
            //     id: tourIds.sessionSummaries,
            //     content:
            //       'Nicely done. Now write a brief summary or instruction to go alongside this bit.',
            //     nextCondition: !!playerSteps.find(
            //       (s) =>
            //         s.stepType === 'intro' &&
            //         s.subSteps.length > 0 &&
            //         // @ts-ignore
            //         !!s.subSteps[0].summary
            //     ),
            //     nextInstruction: 'Write a summary of your video to continue',
            //   },
            //   {
            //     id: tourIds.sessionFirstStep,
            //     content:
            //       'Great! Now you can add the first step. Each step of your session can contain several bits - short nuggets of information just like your intro.',
            //     nextCondition: !!playerSteps.find((s) => s.stepType === 'normal'),
            //     nextInstruction: 'Add a new step to continue',
            //   },
            //   {
            //     id: tourIds.sessionOutlineView,
            //     content:
            //       'If you want to jot down each step of your session to give it structure first, you can head to the Outline View at any time.',
            //   },
            //   {
            //     id: tourIds.sessionPublish,
            //     content:
            //       "You're well on your way to creating your first session!\n\nOnly you can view your session right now – when you're done, check in with your 'To Do' list and publish your session to share it with the world 🌎",
            //   },
            // ]}
          />
        )}
    </>
  );
};

const mapStateToProps = (state: GlobalState, ownProps: OwnProps) => {
  const { sessionId, courseId } = ownProps.match.params;
  const {
    cms: {
      session: sessionState,
      step: stepState,
      videoClip: { videoClipList },
      course: { course: courseState },
    },
  } = state;

  const session = sessionState.session[sessionId];
  const steps = session
    ? getStepsForSession(stepState.stepList, session.id)
    : {};

  /** Build a list of question Ids - used to fetch question data */
  const questionIds = Object.keys(steps)
    .map((k) => steps[k])
    .reduce(
      (acc: number[], { questions }) =>
        questions ? [...acc, ...questions] : [...acc],
      []
    );

  /** Build list of questions - after having fetched question data */
  const questions = questionIds
    .map((id) => stepState.question[id])
    .filter((a) => a);

  /** Build list of video clips that haven't been assigned to a specific step */
  const unassignedVideoClips = getVideoClipsForSession(
    videoClipList,
    parseInt(sessionId)
  );

  const videoClips = getVideoClipsForSteps(
    videoClipList,
    Object.values(steps).map((s) => s.id)
  );

  const course = session && courseState[session.course];

  return {
    questionIds,
    questions,
    moduleQuestions: stepState.question,
    session,
    sessionUI: state.ui.session,
    stepCount: Object.keys(steps).length,
    steps,
    categoryOptions: getCategoryOptions(state),
    stepsUI: state.ui.step,
    videoClips,
    unassignedVideoClips: Object.values(unassignedVideoClips),
    videoClipUI: state.ui.videoClip,
    course,
    courseParam: courseId,
  };
};

const connector = connect(mapStateToProps);

export default connector(SessionEditScreen);
