import pickBy from 'lodash/pickBy';
import { SessionAT, UnitAT, AssessmentAT } from 'redux/actionTypes/cms';

import { Action } from 'types';
import {
  SessionListState,
  SessionListAllState,
  SessionDetailState,
  SessionUIState,
  ISessionListItem,
  ISessionListAllItem,
  SessionSummaryState,
} from 'types/cms';

const initialState: SessionListState = {};

export const sessionListReducer = (
  state = initialState,
  action: Action
): SessionListState => {
  switch (action.type) {
    case SessionAT.CMS_CREATE_SESSION_REQUEST:
    case SessionAT.CMS_FETCH_SESSION_LIST_REQUEST:
      return state;
    case SessionAT.CMS_CREATE_SESSION_SUCCESS:
    case SessionAT.CMS_FETCH_SESSION_LIST_SUCCESS:
    case UnitAT.CMS_FETCH_UNIT_LIST_SUCCESS:
    case UnitAT.CMS_CREATE_UNIT_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.sessions,
      };
    case SessionAT.CMS_CREATE_SESSION_FAILURE:
    case SessionAT.CMS_FETCH_SESSION_LIST_FAILURE:
      return state;
    case SessionAT.CMS_FETCH_SESSION_SUCCESS:
    case SessionAT.CMS_PATCH_SESSION_SUCCESS: {
      // When we get or update a session, we find that session in the
      // list and overwrite properties in the list representation from
      // the detailed object received.
      const idString = action.payload.result.toString();
      const session = action.payload.entities.session[idString];

      // If we don't have a list representation of the session yet
      // then do nothing
      const existingItem = state[idString];
      if (!existingItem || Object.keys(state).length === 0) return state;

      const item = pickBy<ISessionListItem>(
        {
          ...existingItem,
          ...session,
        },
        (val, k) => Object.keys(existingItem).includes(k)
      ) as ISessionListItem;

      return {
        ...state,
        [idString]: item,
      };
    }
    case SessionAT.CMS_DELETE_SESSION_SUCCESS: {
      let sessionId = action.meta.sessionId as string;
      if (typeof sessionId === 'boolean') return state;

      let { [sessionId]: deletedSession, ...rest } = state;

      if (deletedSession) {
        // When removing a session, we need to make sure that the index of any
        // sessions with a higher index than the deleted one are decremented by 1
        return Object.values(rest).reduce<SessionListState>(
          (acc, session: ISessionListItem) => {
            if (
              session.unit === deletedSession.unit &&
              session.index > deletedSession.index
            ) {
              acc[session.id] = {
                ...session,
                index: session.index - 1,
              };
            } else {
              acc[session.id] = session;
            }
            return acc;
          },
          {}
        );
      }
    }
    default:
      return state;
  }
};

const sessionInitialState: SessionDetailState = {};

export const sessionReducer = (
  state = sessionInitialState,
  action: Action
): SessionDetailState => {
  switch (action.type) {
    case SessionAT.CMS_FETCH_SESSION_REQUEST:
      return state;
    case SessionAT.CMS_FETCH_SESSION_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.session,
      };
    case SessionAT.CMS_FETCH_SESSION_FAILURE:
      return state;
    case SessionAT.CMS_PATCH_SESSION_REQUEST:
      return state;
    case SessionAT.CMS_PATCH_SESSION_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.session,
      };
    case SessionAT.CMS_PATCH_SESSION_FAILURE:
      return state;
    default:
      return state;
  }
};

const sessionSummaryInitialState: SessionSummaryState = {};

export const sessionSummaryReducer = (
  state = sessionSummaryInitialState,
  action: Action
): SessionSummaryState => {
  switch (action.type) {
    // We don't worry about updating session summary data upon
    // `CMS_PATCH_SESSION_SUCCESS` like we do with session list
    // data, as session summary data is always re-retrieved before
    // use
    case AssessmentAT.CMS_COHORT_ASSESSMENTS_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.sessionSummary,
      };
    default:
      return state;
  }
};

export const sessionListAllReducer = (
  state = initialState,
  action: Action
): SessionListAllState => {
  switch (action.type) {
    case SessionAT.CMS_FETCH_ALL_SESSIONS_REQUEST:
      return state;
    case SessionAT.CMS_FETCH_ALL_SESSIONS_SUCCESS:
      const sessionState = Object.values(
        action.payload.entities.sessions
        // ts-ignore
      ).reduce<SessionListAllState>((acc, session: ISessionListItem) => {
        // For this state, only allow "normal" module types
        if (session.moduleType !== 'intro' && session.moduleType !== 'outro') {
          acc[session.id] = session;
        }
        return acc;
      }, {});
      return {
        ...state,
        // ts-ignore
        ...sessionState,
      };
    case SessionAT.CMS_FETCH_ALL_SESSIONS_FAILURE:
      return state;
    case SessionAT.CMS_FETCH_SESSION_SUCCESS:
    case SessionAT.CMS_PATCH_SESSION_SUCCESS: {
      // When we get or update a session, we find that session in the
      // list and overwrite properties in the list representation from
      // the detailed object received.
      const idString = action.payload.result.toString();
      const session = action.payload.entities.session[idString];

      // For this state, only allow "normal" module types
      if (session.moduleType === 'intro' || session.moduleType === 'outro')
        return state;
      // If we don't have a list representation of the session yet
      // then do nothing
      const existingItem = state[idString];
      if (!existingItem || Object.keys(state).length === 0) return state;

      const item = pickBy<ISessionListAllItem>(
        {
          ...existingItem,
          ...session,
        },
        (val, k) => Object.keys(existingItem).includes(k)
      ) as ISessionListAllItem;

      return {
        ...state,
        [idString]: item,
      };
    }
    case SessionAT.CMS_DELETE_SESSION_SUCCESS: {
      let sessionId = action.meta.sessionId as string;
      if (typeof sessionId === 'boolean') return state;
      let { [sessionId]: deletedSession, ...rest } = state;
      return rest;
    }
    default:
      return state;
  }
};

export const sessionUIReducer = (
  state: SessionUIState = {
    session: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
    sessionList: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
  },
  action: Action
): SessionUIState => {
  switch (action.type) {
    case SessionAT.CMS_CREATE_SESSION_REQUEST:
    case SessionAT.CMS_FETCH_SESSION_LIST_REQUEST:
    case SessionAT.CMS_FETCH_ALL_SESSIONS_REQUEST:
      return {
        ...state,
        sessionList: {
          ...state.sessionList,
          loading: !action.error,
        },
      };
    case SessionAT.CMS_CREATE_SESSION_SUCCESS:
    case SessionAT.CMS_FETCH_SESSION_LIST_SUCCESS:
    case SessionAT.CMS_FETCH_ALL_SESSIONS_SUCCESS:
    case SessionAT.CMS_CREATE_SESSION_FAILURE:
    case SessionAT.CMS_FETCH_SESSION_LIST_FAILURE:
    case SessionAT.CMS_FETCH_ALL_SESSIONS_FAILURE:
      return {
        ...state,
        sessionList: {
          ...state.sessionList,
          loading: false,
        },
      };
    case SessionAT.CMS_FETCH_SESSION_REQUEST:
    case SessionAT.CMS_PATCH_SESSION_REQUEST:
    case SessionAT.CMS_DELETE_SESSION_REQUEST:
      return {
        ...state,
        session: {
          ...state.session,
          loading: !action.error,
        },
      };
    case SessionAT.CMS_FETCH_SESSION_SUCCESS:
    case SessionAT.CMS_PATCH_SESSION_SUCCESS:
    case SessionAT.CMS_DELETE_SESSION_SUCCESS:
    case SessionAT.CMS_FETCH_SESSION_FAILURE:
    case SessionAT.CMS_PATCH_SESSION_FAILURE:
    case SessionAT.CMS_DELETE_SESSION_FAILURE:
      return {
        ...state,
        session: {
          ...state.session,
          loading: false,
        },
      };
    default:
      return state;
  }
};
