import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
// @ts-ignore
import webvtt from 'node-webvtt';

import { CLIP_NAME } from 'constants/common';
import { PRO_ORGS } from 'constants/organisation';

import { videoClipActions } from 'redux/actions/cms';

import styled, {
  Box,
  Flex,
  Stack,
  Grid,
  GridItem,
  MdIcon,
  Text,
  Textarea,
  Spinner,
  Button,
  AlertDialogButton,
  Image,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  useTheme,
  LightMode,
  DarkMode,
  Divider,
  chakra,
  useMediaQuery,
} from '@workshop/ui';

import { PLATFORM } from 'constants/env';
import { STATUS } from 'constants/background';
import { useUploadList } from 'redux/selectors/background';
import { useCurrentTeamProfile, useCurrentTeam } from 'redux/selectors';
import { hooks } from 'utils';

import {
  StandaloneImageUpload,
  LabelTextArea,
  RenderHtml,
  ProCta,
} from 'components/Common';
import { UploadStatus } from 'components/AppHeader/UploadStatusButton';
import { AudioVisualizer } from 'components/AudioVisualizer';
import { MediaRecorder } from 'components/MediaRecorder';

import { ClipProgress, VideoClipsPlayerProps, VideoPlayerProps } from './types';

export const orientationStyleMapping = {
  portrait: {
    width: '100%',
    paddingTop: '177.7%',
  },
  landscape: {
    width: '100%',
    height: '0',
    paddingTop: '28.2%',
    paddingBottom: '28.2%',
  },
  square: {
    width: '100%',
    paddingTop: '100%',
  },
};

export const containerStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
} as const;

export const StyledVideo = styled.div`
  width: 100%;
  height: 100%;
  video {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

type SubtitleCue = {
  start: number;
  end: number;
  text: string;
  styles: string;
  identifier: string;
  keyId: number;
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const MEDIA_ELEMENT_STREAMS = new WeakMap();
const audioCtx = new window.AudioContext();

const SubtitleEntry: React.FC<{
  subtitle: SubtitleCue;
  isVisible: boolean;
  isPaused: boolean;
  onChangeText: (newText: string) => void;
  onRemoveSubtitle: () => void;
}> = ({ subtitle, isVisible, isPaused, onChangeText, onRemoveSubtitle }) => {
  return (
    <Flex
      position="absolute"
      bottom={20}
      left="5%"
      right="5%"
      zIndex={2}
      justifyContent="center"
      display={isVisible ? 'flex' : 'none'}
      flexDirection="column"
    >
      <Flex position="relative">
        <Flex
          position="absolute"
          right={0}
          top={-8}
          pointerEvents={isPaused ? 'auto' : 'none'}
        >
          <DarkMode>
            <Button
              onClick={() => onRemoveSubtitle()}
              variant="outline"
              colorScheme="red"
              size="xs"
              icon="DeleteOutline"
              w={6}
              disabled={!isPaused}
              _disabled={{
                cursor: 'not-allowed',
                opacity: 0.8,
              }}
            />
          </DarkMode>
        </Flex>
        <Textarea
          bg="rgba(0,0,0,0.5)"
          color="#fff"
          p={2}
          mb={2}
          borderRadius="sm"
          fontWeight="semibold"
          textAlign="center"
          maxH="200px"
          resize="none"
          defaultValue={subtitle.text}
          flex={1}
          placeholder={`Write your captions here...`}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            onChangeText(e.target.value);
          }}
        />
      </Flex>
    </Flex>
  );
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  id,
  autoplay,
  autoPlayNext = false,
  clip,
  nextIdx,
  currentIdx,
  orientation,
  stepsTotal,
  isEditable = false,
  onUpload,
  qrBlob,
  mediaType,
  onSaveClip,
  hasMedia,
  embed,
  onBlurBackground,
}) => {
  const fileInput = useRef<HTMLInputElement>();
  const {
    onClickNext,
    onClickPrev,
    showNextBtn,
    showPrevBtn,
    src: rawSrc,
    srcHq,
    summary,
    script,
    subtitles,
  } = clip;
  const src = rawSrc
    .replace('d3d3hhzqz2hzg8.cloudfront.net', 'cdn-dev.workshop.ws')
    .replace('d1bqmh4sljm7sh.cloudfront.net', 'cdn.workshop.ws');

  const orientationStyles = orientationStyleMapping[orientation];

  const playerRef = useRef<ReactPlayer>(null);
  const autoPlayTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const dispatch = useDispatch();

  const [clipProgress, setClipProgress] = useState<ClipProgress>({
    // Amount of video loaded & played (%)
    loaded: 0,
    played: 0,
    // Amount of video loaded & played (seconds)
    loadedSeconds: 0,
    playedSeconds: 0,
  });

  const [clipDuration, setClipDuration] = useState(0);
  const [clipLoaded, setClipLoaded] = useState(false);
  const [clipPausedTime, setClipPausedTime] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);

  // Init state for handling the animating/transitioning of video clips
  // on clip change.
  const [screenshot, setScreenshot] = useState('');
  const [screenshotOpacity, setScreenshotOpacity] = useState('0%');
  const [clipTranslations, setClipTranslations] = useState({
    screenshotTranslateX: '0%',
    videoTranslateX: '0%',
  });
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isIndicatorTransitioning, setIsIndicatorTransitioning] =
    useState(false);
  const [recordingClip, setRecordingClip] = useState<'video' | 'audio' | null>(
    null
  );
  const [isEditing, setIsEditing] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [playbackError, setPlaybackError] = useState(false);
  const [appStore, setAppStore] = useState<'ios' | 'android' | null>(null);
  const [showQrCode, setShowQrCode] = useState(false);
  const [subtitlesText, setSubtitlesText] = useState(null);
  const [showSubtitles, setShowSubtitles] = useState(false);
  const [dyslexicFontActive, setDyslexicFontActive] = useState(false);

  const [showSubtitlesEditor, setShowSubtitlesEditor] = useState(false);
  const [editedSubtitles, setEditedSubtitles] = useState<SubtitleCue[]>([]);
  const [parsedVtt, setParsedVtt] = useState({
    valid: true,
    strict: true,
    cues: [],
    errors: [],
  });
  const [activeSubtitleIdx, setActiveSubtitleIdx] = useState<number | null>(0);
  const [canAddSubtitle, setCanAddSubtitle] = useState(false);
  // These are used for keeping track of subtitle timing changes
  const [prevSubtitleStart, setPrevSubtitleStart] = useState(0);
  const [prevSubtitleEnd, setPrevSubtitleEnd] = useState(0);
  const [isChangingSubtitleTiming, setIsChangingSubtitleTiming] =
    useState(false);

  useEffect(() => {
    if (!isPlaying) {
      const currentTime = playerRef.current?.getCurrentTime();
      if (currentTime !== undefined) {
        setClipPausedTime(currentTime);
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (!isPlaying) {
      findActiveSubtitleIdx(clipPausedTime / clipDuration);
    }
  }, [editedSubtitles.length]);

  const sortedSubtitles = editedSubtitles.sort((a, b) => a.start - b.start);

  // const [isPreviewingEmbed, setIsPreviewingEmbed] = useState(false);
  const [embedInput, setEmbedInput] = useState<string>('');

  useEffect(() => {
    if (showSubtitlesEditor && subtitles) {
      const downloadVtt = async (url: string) => {
        try {
          const response = await fetch(url);
          const vttText = await response.text();
          const parsed = webvtt.parse(vttText);
          setParsedVtt(parsed);
          if (parsed.cues) {
            setEditedSubtitles(
              parsed.cues.map((c: SubtitleCue) => ({
                ...c,
                keyId: Math.round(Math.random() * 10000),
              }))
            );
          }
        } catch (error) {
          console.error('VTT error: ', error);
        }
      };
      downloadVtt(subtitles);
    }
  }, [showSubtitlesEditor, subtitles]);

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  let embedCode = embedInput;
  const [, embedUrl] = /<iframe[^>]*\s+src=["'](.*?)["']/.exec(embedCode) ?? [];
  embedCode = embedUrl
    ? `<iframe width="720" height="405" src="${embedUrl}" ${
        // Allow specific embeds correct permissions
        embedUrl.includes('https://www.videoask.com/')
          ? `allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"`
          : embedUrl.includes('https://www.youtube.com/')
          ? `allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"`
          : ''
      }></iframe>`
    : '';

  const [stream, setStream] = useState<MediaStream | null>(null);

  const [indicatorWidths, setIndicatorWidths] = useState<{
    current: number | '150px';
    next: number | '150px';
  }>({
    current: '150px',
    next: 2,
  });

  const [isTouchDevice] = useMediaQuery('(hover: none)');
  const [isMobile] = useMediaQuery('(max-width: 500px)');
  const [isTablet] = useMediaQuery('(max-width: 767px)');

  const theme = useTheme();

  const handleGenerateSubtitleFile = async () => {
    const parsedSubtitles = { ...parsedVtt, cues: sortedSubtitles };
    const subtitlesContent = webvtt.compile(parsedSubtitles);
    const subtitlesBlob = new Blob([subtitlesContent], {
      type: 'text/vtt',
    });
    if (onSaveClip) {
      await onSaveClip({ subtitlesBlob });
    }
    setShowSubtitlesEditor(false);
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
      setIsPlaying(true);
    }, 1);
  };

  const animateIndicators = async () => {
    setIsIndicatorTransitioning(true);
    await timeout(200);
    setIndicatorWidths({ current: 2, next: '150px' });
    await timeout(500);
    setIndicatorWidths({ current: '150px', next: 2 });
    setIsIndicatorTransitioning(false);
  };

  const changeClip = async (direction: 'next' | 'previous') => {
    /**
     * To create a swiping animation from clip to clip, we take a screenshot
     * of the current state of the video and position the resulting image over the
     * video, we then run a string of CSS changes to move the video (which will contain
     * a new clip source) to the side of the screenshot, then animate the image and
     * video together to create the illusion of swiping from one clip to another
     * (animations are set via css "transitions" on the relevant elements)
     */

    animateIndicators();

    // Canvas screenshot doesn't currently work on Mac Safari, so skip animation on Safari
    // @ts-ignore
    const isSafari = window.safari !== undefined;
    if (isSafari) return;
    setIsTransitioning(true);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const video = playerRef.current?.getInternalPlayer();

    if (context && video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // "Double request animation frame" to ensure smooth animation
      requestAnimationFrame(() => {
        requestAnimationFrame(async () => {
          // Wrapped in a 'try' as it occasionally fails
          try {
            context.drawImage(
              video as HTMLVideoElement,
              0,
              0,
              video.videoWidth,
              video.videoHeight
            );
            const dataURI = canvas.toDataURL();
            // Init transition by showing the screenshot overlay
            setScreenshot(dataURI);
            setScreenshotOpacity('100%');
            await timeout(100);
            // Start transition
            setClipTranslations({
              screenshotTranslateX: '0%',
              videoTranslateX: direction === 'next' ? '100%' : '-100%',
            });
            await timeout(500);
            requestAnimationFrame(() =>
              setClipTranslations({
                screenshotTranslateX: direction === 'next' ? '-100%' : '100%',
                videoTranslateX: '0%',
              })
            );
            await timeout(500);
            // Restart the video playing and reset transition state
            setIsPlaying(true);
            setScreenshotOpacity('0%');
            setScreenshot('');
            await timeout(100);
            setClipTranslations({
              screenshotTranslateX: '0%',
              videoTranslateX: '0%',
            });
          } catch {}
        });
      });
    }
    setIsTransitioning(false);
  };

  useEffect(() => {
    const closedCaptions = localStorage.getItem('closedCaptions');
    if (closedCaptions === 'on') {
      setShowSubtitles(true);
    }
    const dyslexicFont = localStorage.getItem('dyslexicFont');
    if (dyslexicFont === 'on') {
      setDyslexicFontActive(true);
    }
  }, []);

  // If the `nextIdx` prop changes, animate to the next clip
  useEffect(() => {
    if (nextIdx !== currentIdx) {
      changeClip(nextIdx > currentIdx ? 'next' : 'previous');
      setStream(null);
    }
  }, [nextIdx, currentIdx]);

  useEffect(() => {
    /**
     * Start playing the video if autoplay is set to true
     * after changing the video source
     */
    if (!src || !autoplay || isPlaying) return;
    setIsPlaying(true);
    setRefreshing(true);
    setProcessing(false);
    setTimeout(() => {
      setRefreshing(false);
    }, 1);
  }, [src]);

  hooks.useDeepEqualEffect(() => {
    /**
     * Runs after handleOnProgress
     * (every 100ms - see progressInterval prop on ReactPlayer)
     */

    /**
     * Move to the next clip and start playing it automatically:
     * - if autoPlayNext is enabled
     * - after the current clip has finished playing (i.e, clipProgress.played === 1)
     * - if there is another clip
     */
    if (!autoPlayNext || clipProgress.played !== 1) return;
    if (!stepsTotal || currentIdx + 1 >= stepsTotal) return;

    onClickNext && onClickNext();
    setIsPlaying(true);
  }, [clipProgress]);

  const handleOnReady = () => {
    if (refreshCount > 0) {
      setRefreshCount(0);
    }
    if (autoplay) {
      // Set isPlaying to false if this timeout isn't cleared in handleOnProgress
      // (i.e. likely that autoplay was blocked by the browser)
      const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
      if (!isFirefox) {
        autoPlayTimeout.current = setTimeout(() => setIsPlaying(false), 2000);
      }
    }
    const video = playerRef.current?.getInternalPlayer();
    const track = video?.getElementsByTagName('track')[0]?.track;
    if (track) {
      track.mode = 'hidden';
    }
  };

  const setAudioStream = () => {
    if (mediaType === 'audio' && !stream) {
      const audio = playerRef.current?.getInternalPlayer();
      if (audio && !audio?.captureStream) {
        // Safari doesn't support captureStream, so we use another method
        // to connect audio stream to visualiser
        if (MEDIA_ELEMENT_STREAMS.has(audio)) {
          setStream(MEDIA_ELEMENT_STREAMS.get(audio));
          return;
        }
        // @ts-ignore
        const sourceNode = audioCtx.createMediaElementSource(audio);
        // Create a stream from our AudioContext
        const dest = audioCtx.createMediaStreamDestination();
        MEDIA_ELEMENT_STREAMS.set(audio, dest.stream);
        // Connect our media element's output to the stream
        sourceNode.connect(dest);
        setStream(dest.stream);
        const analyser = audioCtx.createAnalyser();
        // Audio should output to the analyser.
        sourceNode.connect(analyser);
        // Analyser should output to the audio device.
        analyser.connect(audioCtx.destination);
      } else {
        const audioStream = audio?.captureStream && audio.captureStream();
        if (audioStream && audioStream.getAudioTracks().length > 0) {
          setStream(audioStream);
        }
      }
    }
  };

  const handleOnDuration = (duration: number) => {
    setClipLoaded(true);
    setClipDuration(duration);
  };

  const handleOnPlay = () => {
    if (stream) {
      setStream(null);
    }
  };

  // Setup video player callbacks
  const handleOnProgress = (args: ClipProgress) => {
    setClipProgress(args);
    setAudioStream();
    if (args.played > 0 && autoPlayTimeout.current) {
      clearTimeout(autoPlayTimeout.current);
    }
    const video = playerRef.current?.getInternalPlayer();
    const track = video?.getElementsByTagName('track')[0]?.track;
    if (track) {
      track.mode = 'hidden';
    }
    const cue = track?.activeCues ? track.activeCues[0] : null;
    if (cue) {
      // @ts-ignore
      const cueText = cue.text;
      setSubtitlesText(cueText);
    } else if (subtitlesText) {
      setSubtitlesText(null);
    }
    if (showSubtitlesEditor) {
      if (activeSubtitleIdx === null) {
        findActiveSubtitleIdx(args.played);
      } else if (sortedSubtitles[activeSubtitleIdx]) {
        const activeStartFraction =
          sortedSubtitles[activeSubtitleIdx].start / clipDuration;
        const activeEndFraction =
          sortedSubtitles[activeSubtitleIdx].end / clipDuration;
        if (
          args.played < activeStartFraction ||
          args.played > activeEndFraction
        ) {
          findActiveSubtitleIdx(args.played);
        }
      }
    }
  };

  const handleOnError = (e: any, data: any) => {
    if (data?.response?.code === 403 && data?.details === 'manifestLoadError') {
      if (refreshCount < 10) {
        setRefreshing(true);
        setRefreshCount(refreshCount + 1);
        // Poll for HLS asset after uploading
        setTimeout(() => setRefreshing(false), 10000);
      } else {
        // Try refreshing content 10 times, then fall back to error state
        setPlaybackError(true);
      }
    }
  };

  // If there is an in progress or completed video upload related to this
  // video clip, then extract it from the state so that we're able to display
  // upload progress metadata.
  const uploadList = useUploadList();
  const clipUpload = Object.values(uploadList).find(
    ({ metadata }) =>
      metadata && 'id' in metadata && metadata.id === id.toString()
  );

  // If an upload exists for this clip and the status isn't `completed`
  // then we have an in progress upload
  const uploadInProgress = clipUpload && clipUpload.status !== STATUS.completed;

  useEffect(() => {
    if (uploadInProgress) {
      setProcessing(true);
    }
  }, [uploadInProgress]);

  const showControls =
    (clipLoaded || mediaType === 'image') &&
    (!isEditable || src) &&
    !isEditing &&
    !playbackError &&
    !refreshing &&
    !uploadInProgress &&
    !isTransitioning &&
    mediaType !== 'embed';

  const progressPercent =
    mediaType === 'image' || !showControls ? 100 : clipProgress.played * 100;

  const blurBackground =
    mediaType === 'text'
      ? true
      : mediaType === 'image' && src
      ? !isPlaying
      : !showSubtitlesEditor &&
        !playbackError &&
        !refreshing &&
        !uploadInProgress &&
        (!isPlaying || progressPercent === 100) &&
        !isTransitioning;

  useEffect(() => {
    if (onBlurBackground) {
      onBlurBackground(blurBackground);
    }
  }, [blurBackground, onBlurBackground]);

  const mediaTypes: {
    name: string;
    icon: string;
    slug: 'video' | 'audio' | 'image' | 'text' | 'embed';
  }[] = [
    {
      name: 'Video',
      icon: 'Movie',
      slug: 'video',
    },
    {
      name: 'Audio',
      icon: 'Mic',
      slug: 'audio',
    },
    {
      name: 'Picture',
      icon: 'PhotoCamera',
      slug: 'image',
    },
    {
      name: 'Just Text',
      icon: 'Title',
      slug: 'text',
    },
    {
      name: 'Embed',
      icon: 'Code',
      slug: 'embed',
    },
  ];

  const findActiveSubtitleIdx = (progressFraction: number) => {
    if (isChangingSubtitleTiming) {
      return;
    }
    let subtitleFound = false;
    let isInsideBuffer = false;
    for (const [idx, s] of sortedSubtitles.entries()) {
      const startFraction = s.start / clipDuration;
      const endFraction = s.end / clipDuration;
      if (progressFraction > startFraction && progressFraction < endFraction) {
        subtitleFound = true;
        if (activeSubtitleIdx !== idx) {
          setActiveSubtitleIdx(idx);
        }
        if (canAddSubtitle) {
          setCanAddSubtitle(false);
        }
        return;
      }
      const startBufferFraction = (s.start - 1) / clipDuration;
      const endBufferFraction = (s.end + 0.2) / clipDuration;
      const endOfClipBufferFraction = (clipDuration - 1) / clipDuration;
      if (
        (progressFraction > startBufferFraction &&
          progressFraction < endBufferFraction) ||
        progressFraction > endOfClipBufferFraction
      ) {
        isInsideBuffer = true;
      }
    }
    if (!subtitleFound) {
      if (activeSubtitleIdx !== null) {
        setActiveSubtitleIdx(null);
      }
      if (isInsideBuffer) {
        if (canAddSubtitle) {
          setCanAddSubtitle(false);
        }
      } else {
        if (!canAddSubtitle) {
          setCanAddSubtitle(true);
        }
      }
    }
  };

  const indicators = showSubtitlesEditor ? (
    <Flex
      width="100%"
      justifyContent="center"
      alignItems="center"
      zIndex={3}
      pointerEvents="auto"
    >
      <Flex
        position="relative"
        width="90%"
        marginX={1}
        justifyContent="center"
        alignItems="center"
        mb={4}
      >
        {sortedSubtitles.map((s, idx) => {
          const startPercentage = (100 * s.start) / clipDuration;
          const endPercentage = (100 * s.end) / clipDuration;
          const widthPercentage = (100 * (s.end - s.start)) / clipDuration;
          if (activeSubtitleIdx === idx) {
            let minValue = 0;
            let maxValue = 100;
            if (idx > 0) {
              minValue = (100 * sortedSubtitles[idx - 1].end) / clipDuration;
            }
            if (idx < sortedSubtitles.length - 1) {
              maxValue = (100 * sortedSubtitles[idx + 1].start) / clipDuration;
            }
            return (
              <Flex
                key={`sub-${s.keyId}`}
                position="absolute"
                left={0}
                right={0}
                mb={9}
              >
                <RangeSlider
                  // min={minValue}
                  // max={maxValue}
                  value={[
                    prevSubtitleStart || startPercentage,
                    prevSubtitleEnd || endPercentage,
                  ]}
                  aria-label={['min', 'max']}
                  minStepsBetweenThumbs={2}
                  role="group"
                  onChangeStart={(val) => {
                    setIsChangingSubtitleTiming(true);
                    setPrevSubtitleStart(val[0]);
                    setPrevSubtitleEnd(val[1]);
                  }}
                  onChange={(val) => {
                    const newStart = Math.max(val[0], minValue);
                    const newEnd = Math.min(val[1], maxValue);
                    if (isPlaying) {
                      setIsPlaying(false);
                    }
                    if (newStart !== prevSubtitleStart) {
                      playerRef.current?.seekTo(newStart / 100, 'fraction');
                      setPrevSubtitleStart(newStart);
                    } else if (newEnd !== prevSubtitleEnd) {
                      playerRef.current?.seekTo(newEnd / 100, 'fraction');
                      setPrevSubtitleEnd(newEnd);
                    }
                  }}
                  onChangeEnd={(val) => {
                    setIsChangingSubtitleTiming(false);
                    const newStart = (val[0] / 100) * clipDuration;
                    const newEnd = (val[1] / 100) * clipDuration;
                    const newEditedSubtitles = [...sortedSubtitles];
                    const newSubtitle = {
                      ...s,
                      start: newStart,
                      end: newEnd,
                    };
                    newEditedSubtitles[idx] = newSubtitle;
                    setEditedSubtitles(newEditedSubtitles);
                    setPrevSubtitleStart(0);
                    setPrevSubtitleEnd(0);
                  }}
                >
                  <RangeSliderTrack height={2} backgroundColor="transparent">
                    <RangeSliderFilledTrack
                      backgroundColor="yellow"
                      borderRadius="full"
                    />
                  </RangeSliderTrack>
                  <RangeSliderThumb
                    index={0}
                    opacity={0}
                    transition="opacity 200ms ease-in"
                    _groupHover={{
                      opacity: progressPercent === 100 ? 0 : 1,
                    }}
                  />
                  <RangeSliderThumb
                    index={1}
                    opacity={0}
                    transition="opacity 200ms ease-in"
                    _groupHover={{
                      opacity: progressPercent === 100 ? 0 : 1,
                    }}
                  />
                </RangeSlider>
                {/* These are here to block clicks before and
                after the slider from changing the range */}
                <Flex
                  position="absolute"
                  zIndex={1}
                  left={0}
                  width={`${Math.max(startPercentage - 2, 0)}%`}
                  mb={8}
                  h={4}
                  display={isChangingSubtitleTiming ? 'none' : 'auto'}
                />
                <Flex
                  position="absolute"
                  zIndex={1}
                  left={`${Math.min(endPercentage + 2, 100)}%`}
                  right={0}
                  mb={8}
                  h={4}
                  display={isChangingSubtitleTiming ? 'none' : 'auto'}
                />
              </Flex>
            );
          }
          return (
            <Flex
              key={`sub-${s.keyId}`}
              position="absolute"
              zIndex={2}
              left={`${startPercentage}%`}
              mb={9}
              width={`${widthPercentage}%`}
              bg={activeSubtitleIdx === idx ? 'yellow' : 'gold'}
              h={2}
              borderRadius="full"
              cursor="pointer"
              _hover={{
                bg: 'goldenrod',
              }}
              onClick={() => {
                setIsPlaying(false);
                setActiveSubtitleIdx(idx);
                playerRef.current?.seekTo(
                  (startPercentage + 0.001) / 100,
                  'fraction'
                );
                findActiveSubtitleIdx((startPercentage + 0.001) / 100);
              }}
            />
          );
        })}
        {activeSubtitleIdx === null && canAddSubtitle && (
          <Flex
            position="absolute"
            left={`${(100 * clipPausedTime) / clipDuration}%`}
            width={4}
            h={4}
            ml={-2}
            mb={9}
            cursor="pointer"
            color="gold"
            _hover={{
              color: 'goldenrod',
            }}
            onClick={() => {
              const newStart = Math.max(
                Math.round(clipPausedTime * 100) / 100 - 0.01,
                0
              );
              const newEnd = Math.min(
                Math.round(clipPausedTime * 100) / 100 + 0.99,
                clipDuration
              );
              const newSubtitle = {
                start: newStart,
                end: newEnd,
                text: '',
                styles: '',
                identifier: '',
                keyId: Math.round(Math.random() * 10000),
              };
              const newEditedSubtitles = [...sortedSubtitles, newSubtitle].sort(
                (a, b) => a.start - b.start
              );
              setCanAddSubtitle(false);
              setEditedSubtitles(newEditedSubtitles);
              setActiveSubtitleIdx(
                newEditedSubtitles.map((ns) => ns.start).indexOf(newStart)
              );
            }}
            justifyContent="center"
            alignItems="center"
            opacity={isPlaying ? 0 : 1}
            pointerEvents={isPlaying ? 'none' : 'auto'}
            transition="opacity 200ms ease-in"
          >
            <MdIcon name="AddCircle" boxSize={4} />
          </Flex>
        )}

        <Slider
          aria-label="Video-progress"
          flex={1}
          focusThumbOnChange={false}
          value={progressPercent}
          onChange={(value) => {
            const progressFraction = value / 100;
            playerRef.current?.seekTo(progressFraction, 'fraction');
            if (!isPlaying) {
              setClipPausedTime(progressFraction * clipDuration);
            }
            if (activeSubtitleIdx === null) {
              findActiveSubtitleIdx(progressFraction);
            } else if (sortedSubtitles[activeSubtitleIdx]) {
              const activeStartFraction =
                sortedSubtitles[activeSubtitleIdx].start / clipDuration;
              const activeEndFraction =
                sortedSubtitles[activeSubtitleIdx].end / clipDuration;
              if (
                progressFraction < activeStartFraction ||
                progressFraction > activeEndFraction
              ) {
                findActiveSubtitleIdx(progressFraction);
              }
            }
          }}
          role="group"
        >
          <SliderTrack height={2} backgroundColor="neutralLightAlpha.400">
            <SliderFilledTrack
              backgroundColor="common.progress"
              transition="width 100ms linear"
            />
          </SliderTrack>
          <SliderThumb
            opacity={0}
            transition="opacity 200ms ease-in, left 100ms linear"
            _groupHover={{
              opacity: progressPercent === 100 ? 0 : 1,
            }}
          />
        </Slider>
      </Flex>
      <Flex position="absolute" bottom={4} left="5%">
        <Box
          backgroundColor="neutralLightAlpha.300"
          _hover={{ backgroundColor: 'neutralLightAlpha.400' }}
          borderRadius="50%"
          cursor="pointer"
          padding={1}
          onClick={(e) => {
            setIsPlaying(!isPlaying);
          }}
        >
          <MdIcon
            name={isPlaying ? 'Pause' : 'PlayArrow'}
            color="white"
            boxSize={4}
          />
        </Box>
      </Flex>
      <Flex position="absolute" bottom={4} right="5%">
        <AlertDialogButton
          alertHeader="Exit Captions Editor"
          alertBody="Any changes will be lost if you exit without saving."
          submitBtnLabel="Exit Without Saving"
          submitBtnColor="red"
          onSubmit={() => setShowSubtitlesEditor(false)}
          onCancel={() => {}}
          size="xs"
          secondary
          icon="Close"
          w={6}
          mr={2}
        />
        <Button
          size="xs"
          onClick={() => handleGenerateSubtitleFile()}
          icon="Done"
          iconPosition="right"
        >
          Save
        </Button>
      </Flex>
      <Flex
        position="absolute"
        top={6}
        pointerEvents="none"
        alignItems="center"
      >
        <MdIcon name="ClosedCaption" color="white" boxSize={6} mr={2} />
        <Text color="white" fontWeight="bold">
          Captions Editor
        </Text>
      </Flex>
    </Flex>
  ) : (
    <Flex width="100%" alignItems="center" zIndex={3} pointerEvents="auto">
      <Flex width="20%" justifyContent="center" h={7}>
        {Boolean(showPrevBtn && onClickPrev) && (
          <>
            <LightMode>
              <Button
                icon="ArrowBack"
                colorScheme="blackAlpha"
                opacity={0.8}
                borderRadius="full"
                height={7}
                width={7}
                minWidth={7}
                px={0}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickPrev && onClickPrev();
                }}
              />
            </LightMode>
          </>
        )}
      </Flex>
      {/** CLIP(s) PROGRESS INDICATORS */}
      <Flex width="60%" justifyContent="center" alignItems="center">
        {Array.from(Array(stepsTotal)).map((c, idx) =>
          idx === currentIdx && !isIndicatorTransitioning ? (
            <Slider
              key={`slider-${idx}`}
              aria-label="Video-progress"
              marginX={1}
              flex={1}
              maxW="150px"
              focusThumbOnChange={false}
              value={progressPercent}
              onChange={(value) => {
                playerRef.current?.seekTo(value / 100, 'fraction');
              }}
            >
              <SliderTrack height={2} backgroundColor="neutralLightAlpha.400">
                <SliderFilledTrack
                  backgroundColor="common.progress"
                  transition="width 100ms linear"
                />
              </SliderTrack>
              <SliderThumb
                opacity={0}
                transition="opacity 200ms ease-in, left 100ms linear"
                _groupHover={{
                  opacity: progressPercent === 100 ? 0 : 1,
                }}
              />
            </Slider>
          ) : (
            <Box
              key={`indicator-${idx}`}
              backgroundColor={
                idx < currentIdx ||
                (isIndicatorTransitioning &&
                  idx === currentIdx &&
                  nextIdx > currentIdx)
                  ? 'common.progress'
                  : 'neutralLightAlpha.500'
              }
              borderRadius="4px"
              minW={2}
              height={2}
              marginX={1}
              transition="width 0.4s"
              {...(isIndicatorTransitioning
                ? {
                    width:
                      idx === currentIdx
                        ? indicatorWidths.current
                        : idx === nextIdx
                        ? indicatorWidths.next
                        : 2,
                  }
                : {
                    width: 2,
                  })}
            />
          )
        )}
      </Flex>
      <Flex width="20%" justifyContent="center" h={7}>
        {Boolean(showNextBtn && onClickNext) && (
          <LightMode>
            <Button
              icon="ArrowForward"
              colorScheme="blackAlpha"
              opacity={0.8}
              borderRadius="full"
              height={7}
              width={7}
              minWidth={7}
              px={0}
              onClick={(e) => {
                e.stopPropagation();
                onClickNext && onClickNext();
              }}
            />
          </LightMode>
        )}
      </Flex>
    </Flex>
  );

  return (
    <Box
      {...orientationStyles}
      position="relative"
      transition={{ base: 'all 0.5s', lg: '' }}
    >
      <Flex
        {...containerStyle}
        alignItems="center"
        backgroundColor={mediaType === 'audio' ? 'background.primary' : 'black'}
        justifyContent="center"
        zIndex={0}
        overflow="hidden"
        // Needed for Safari to respect hidden overflow over video
        transform="translate3d(0, 0, 0)"
      >
        <StyledVideo>
          {screenshot ? (
            <Image
              src={screenshot}
              opacity={screenshotOpacity}
              position="absolute"
              width="100%"
              height="100%"
              objectFit="cover"
              transform={`translateX(${clipTranslations.screenshotTranslateX})`}
              transition="opacity 0.1s, transform 0.5s ease-in-out"
              backgroundColor="background.tint4"
              zIndex={1}
            />
          ) : null}
          {(!isEditable || src) &&
            !clipLoaded &&
            mediaType !== 'image' &&
            mediaType !== 'embed' &&
            !playbackError && (
              <Flex
                alignItems="center"
                backgroundColor="neutralAlpha.200"
                height="100%"
                justifyContent="center"
                width="100%"
                zIndex={1}
              >
                <Spinner color="neutral.700" />
              </Flex>
            )}
          {uploadInProgress && clipUpload ? (
            <Flex
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              flexDir="column"
              zIndex={1}
            >
              <Flex maxW="400px" px={12} mb={8}>
                <UploadStatus
                  id={id.toString()}
                  upload={clipUpload}
                  displayText={false}
                  displayBytes
                  light
                  centered
                />
              </Flex>
            </Flex>
          ) : processing ? (
            <Flex
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              backgroundColor="neutral.300"
              flexDirection="column"
              textAlign="center"
              p={6}
            >
              <Spinner color="neutral.700" size="sm" />
              {mediaType === 'video' && (
                <>
                  <Text color="neutral.700" mt={4} fontWeight="semibold">
                    Video processing
                  </Text>
                  <Text color="neutral.700" mt={2} fontSize="sm">
                    You can keep working on your session, this might take a few
                    minutes.
                  </Text>
                </>
              )}
            </Flex>
          ) : null}
          <Flex
            width="calc(100% + 2px)"
            height="100%"
            transform={`translateX(${clipTranslations.videoTranslateX})`}
            transition="transform 0.5s ease-in-out"
          >
            {Boolean(isEditable && ((hasMedia && !src) || isEditing)) &&
              !!onSaveClip && (
                <Flex
                  width="100%"
                  height="100%"
                  backgroundColor={
                    isEditing
                      ? 'background.defaultTransparent'
                      : 'background.tint1'
                  }
                  alignItems="center"
                  justifyContent="center"
                  flexDir="column"
                  position="absolute"
                  pb={mediaType === 'embed' ? 0 : 12}
                  zIndex={1}
                >
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    flexDir="column"
                    p={4}
                    position="relative"
                    w="100%"
                    h="100%"
                  >
                    {!mediaType && (
                      <Text
                        textAlign="center"
                        fontSize="sm"
                        fontWeight="semibold"
                        maxWidth={150}
                        color="text.muted"
                        pb={4}
                      >
                        {`Select a media type for this ${CLIP_NAME}:`}
                      </Text>
                    )}
                    {!showQrCode && (
                      <Grid
                        templateColumns={
                          mediaType
                            ? {
                                base: 'repeat(5, 1fr)',
                                lg:
                                  orientation === 'landscape'
                                    ? 'repeat(5, 1fr)'
                                    : 'repeat(4, 1fr)',
                                '2xl': 'repeat(5, 1fr)',
                              }
                            : 'repeat(2, 1fr)'
                        }
                        gap={{ base: 2, sm: 3, md: 4 }}
                      >
                        {mediaTypes.map((mt) => (
                          <GridItem
                            key={`mediaType-${mt.slug}`}
                            colSpan={
                              mt.slug === 'video' && !mediaType
                                ? 2
                                : {
                                    base: 1,
                                    lg:
                                      orientation === 'landscape'
                                        ? 1
                                        : mt.slug === 'video'
                                        ? 4
                                        : 1,
                                    '2xl': 1,
                                  }
                            }
                          >
                            <Flex
                              flex={1}
                              borderWidth={2}
                              borderColor={
                                mt.slug === mediaType
                                  ? 'common.primary'
                                  : 'border.muted'
                              }
                              backgroundColor={
                                mt.slug === mediaType
                                  ? 'background.default'
                                  : 'background.tint1'
                              }
                              borderRadius={mediaType ? 'full' : 'md'}
                              p={mediaType ? 3 : 4}
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              cursor="pointer"
                              _hover={{ backgroundColor: 'background.default' }}
                              onClick={() => onSaveClip({ clipType: mt.slug })}
                              // {...(mt.disabled
                              //   ? {
                              //       pointerEvents: 'none',
                              //       opacity: 0.5,
                              //     }
                              //   : {})}
                            >
                              <Box>
                                <MdIcon
                                  name={mt.icon}
                                  boxSize={6}
                                  color="common.primary"
                                />
                              </Box>
                              {!mediaType && (
                                <Box mt={1}>
                                  <Text
                                    fontSize="md"
                                    fontWeight="bold"
                                    color="common.primary"
                                  >
                                    {mt.name}
                                  </Text>
                                </Box>
                              )}
                            </Flex>
                          </GridItem>
                        ))}
                      </Grid>
                    )}

                    {mediaType === 'video' ? (
                      <Stack
                        direction={
                          orientation === 'landscape' ? 'row' : 'column'
                        }
                        gap={2}
                        mt={6}
                      >
                        {showQrCode ? (
                          <>
                            {appStore === 'ios' ? (
                              <Flex alignItems="center" flexDir="column" m={4}>
                                <Button
                                  size="xs"
                                  variant="ghost"
                                  onClick={() => setAppStore(null)}
                                  icon="ArrowBack"
                                  mb={{ base: 1, md: 2 }}
                                >
                                  Back
                                </Button>
                                <Text
                                  textAlign="center"
                                  fontSize={isMobile ? 'xs' : 'sm'}
                                  fontWeight="semibold"
                                  maxWidth={200}
                                  color="text.muted"
                                  pb={isMobile ? 1 : 3}
                                  pt={1}
                                >
                                  Download the iOS app{' '}
                                  <chakra.a
                                    href="https://apps.apple.com/gb/app/steppit/id1660615987"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    color="text.primary"
                                  >
                                    here
                                  </chakra.a>
                                  :
                                </Text>
                                <Box
                                  backgroundColor="white"
                                  borderRadius="md"
                                  p={2}
                                  boxShadow="lg"
                                >
                                  {/* @ts-ignore */}
                                  <QRCode
                                    value="https://apps.apple.com/gb/app/steppit/id1660615987"
                                    size={
                                      isMobile && orientation !== 'portrait'
                                        ? 80
                                        : 128
                                    }
                                  />
                                </Box>
                              </Flex>
                            ) : appStore === 'android' ? (
                              <Flex alignItems="center" flexDir="column" m={4}>
                                <Button
                                  size="xs"
                                  variant="ghost"
                                  onClick={() => setAppStore(null)}
                                  icon="ArrowBack"
                                  mb={{ base: 1, md: 2 }}
                                >
                                  Back
                                </Button>
                                <Text
                                  textAlign="center"
                                  fontSize={isMobile ? 'xs' : 'sm'}
                                  fontWeight="semibold"
                                  maxWidth={200}
                                  color="text.muted"
                                  pb={isMobile ? 1 : 3}
                                  pt={1}
                                >
                                  Download the Android app{' '}
                                  <chakra.a
                                    href="https://play.google.com/store/apps/details?id=com.steppit.app"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    color="text.primary"
                                  >
                                    here
                                  </chakra.a>
                                  :
                                </Text>
                                <Box
                                  backgroundColor="white"
                                  borderRadius="md"
                                  p={2}
                                  boxShadow="lg"
                                >
                                  {/* @ts-ignore */}
                                  <QRCode
                                    value="https://play.google.com/store/apps/details?id=com.steppit.app"
                                    size={
                                      isMobile && orientation !== 'portrait'
                                        ? 80
                                        : 128
                                    }
                                  />
                                </Box>
                              </Flex>
                            ) : (
                              <Flex
                                alignItems="center"
                                flexDir={
                                  orientation === 'portrait' ? 'column' : 'row'
                                }
                                m={4}
                              >
                                <Flex alignItems="center" flexDir="column">
                                  <Button
                                    size="xs"
                                    icon="ArrowBack"
                                    onClick={() => setShowQrCode(false)}
                                    variant="ghost"
                                    mb={{ base: 1, md: 2 }}
                                  >
                                    Back
                                  </Button>
                                  <Text
                                    textAlign="center"
                                    fontSize={isMobile ? 'xs' : 'sm'}
                                    fontWeight="semibold"
                                    maxWidth={150}
                                    color="text.muted"
                                    pb={isMobile ? 1 : 3}
                                  >
                                    {isMobile
                                      ? 'Scan in the app:'
                                      : 'Scan in the app to film from your phone:'}
                                  </Text>
                                  <Box
                                    backgroundColor="white"
                                    borderRadius="md"
                                    p={2}
                                    boxShadow="lg"
                                    mb={4}
                                  >
                                    {/* @ts-ignore */}
                                    <QRCode
                                      // @ts-ignore
                                      value={qrBlob}
                                      size={
                                        isMobile && orientation !== 'portrait'
                                          ? 80
                                          : 128
                                      }
                                    />
                                  </Box>
                                </Flex>
                                <Flex
                                  display={
                                    orientation === 'portrait' ? 'none' : 'flex'
                                  }
                                  height="100%"
                                  borderRightWidth={1}
                                  mx={{ base: 4, md: 8 }}
                                />
                                <Flex alignItems="center" flexDir="column">
                                  <Button
                                    icon="Refresh"
                                    variant="ghost"
                                    onClick={() =>
                                      dispatch(
                                        videoClipActions.retrieve(id as number)
                                      )
                                    }
                                    size={isMobile ? 'xs' : 'sm'}
                                  >
                                    Refresh Media
                                  </Button>
                                  <Divider my={isMobile ? 2 : 3} />
                                  <Text
                                    textAlign="center"
                                    fontSize="xs"
                                    fontWeight="semibold"
                                    maxWidth={150}
                                    color="text.muted"
                                    pb={3}
                                  >
                                    Haven't got the app?
                                  </Text>
                                  <Button
                                    leftIcon={<FaApple />}
                                    size={isMobile ? 'xs' : 'sm'}
                                    mb={3}
                                    onClick={() => setAppStore('ios')}
                                  >
                                    {isMobile
                                      ? 'Download on iOS'
                                      : 'Download Steppit on iOS'}
                                  </Button>
                                  <Button
                                    leftIcon={<FaGooglePlay />}
                                    size={isMobile ? 'xs' : 'sm'}
                                    // mb={3}
                                    onClick={() => setAppStore('android')}
                                  >
                                    {isMobile
                                      ? 'Download on Android'
                                      : 'Download Steppit on Android'}
                                  </Button>
                                </Flex>
                              </Flex>
                            )}
                          </>
                        ) : (
                          <>
                            <Button
                              onClick={() => setRecordingClip('video')}
                              icon="FiberManualRecord"
                              colorScheme="red"
                              fontSize="sm"
                              isLoading={uploadInProgress}
                              isDisabled={uploadInProgress}
                              display={isTouchDevice ? 'none' : 'block'}
                            >
                              Record Video
                            </Button>
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsPlaying(false);
                                fileInput.current?.click();
                              }}
                              icon="FileUpload"
                              fontSize="sm"
                              isLoading={uploadInProgress}
                              isDisabled={uploadInProgress}
                            >
                              Upload Video
                            </Button>
                            {Boolean(qrBlob) && PLATFORM === 'steppit' && (
                              <Button
                                onClick={() => {
                                  setShowQrCode(true);
                                }}
                                variant="outline"
                                icon="AddToHomeScreen"
                                fontSize="sm"
                                isLoading={uploadInProgress}
                                isDisabled={uploadInProgress}
                              >
                                Film By Phone
                              </Button>
                            )}
                          </>
                        )}

                        {/* {Boolean(qrBlob) && PLATFORM === 'steppit' && (
                          <Flex display={{ base: 'none', md: 'flex' }}>
                            <Popover placement="top" closeOnBlur>
                              <PopoverTrigger>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  colorScheme="gray"
                                  icon="AddToHomeScreen"
                                  fontSize="sm"
                                  isLoading={uploadInProgress}
                                  isDisabled={uploadInProgress}
                                >
                                  Film By Phone
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent
                                width="image.3xl"
                                background={
                                  appStore === null
                                    ? 'background.tint2'
                                    : 'background.primary'
                                }
                              >
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverBody>
                                  
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        )} */}
                      </Stack>
                    ) : mediaType === 'audio' ? (
                      <Stack
                        direction={
                          orientation === 'landscape' ? 'row' : 'column'
                        }
                        gap={2}
                        mt={6}
                      >
                        <Button
                          onClick={() => setRecordingClip('audio')}
                          icon="FiberManualRecord"
                          colorScheme="red"
                          fontSize="sm"
                          isLoading={uploadInProgress}
                          isDisabled={uploadInProgress}
                        >
                          Record Audio
                        </Button>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsPlaying(false);
                            fileInput.current?.click();
                          }}
                          icon="FileUpload"
                          fontSize="sm"
                          isLoading={uploadInProgress}
                          isDisabled={uploadInProgress}
                        >
                          Upload Audio
                        </Button>
                      </Stack>
                    ) : mediaType === 'image' ? (
                      <Stack gap={2} mt={6}>
                        <StandaloneImageUpload
                          id="image"
                          name="image"
                          label=""
                          textColor="text.muted"
                          // textBgColor={
                          //   imagePreview.landscape || landscape
                          //     ? 'background.defaultTransparent'
                          //     : 'transparent'
                          // }
                          textBgColor="transparent"
                          backgroundColor="background.tint3"
                          // height={{
                          //   base: 'image.xl',
                          //   sm: 'image.2xl',
                          //   md: 'image.3xl',
                          //   '2xl': 'image.2xl',
                          // }}
                          // width="100%"
                          width="image.3xl"
                          height="image.3xl"
                          // image={imagePreview.landscape || landscape}
                          image=""
                          onDrop={async (
                            fieldName: string,
                            acceptedFiles: File[]
                          ) => {
                            onSaveClip &&
                              (await onSaveClip({ image: acceptedFiles[0] }));
                            setIsEditing(false);
                          }}
                          backgroundSize="contain"
                          showBlur
                        />
                      </Stack>
                    ) : mediaType === 'embed' ? (
                      <Stack
                        gap={2}
                        mt={isMobile && orientation === 'landscape' ? 2 : 4}
                        textAlign="center"
                      >
                        {isPro ? (
                          <Stack
                            flex={1}
                            direction={
                              isMobile && orientation === 'landscape'
                                ? 'row'
                                : 'column'
                            }
                          >
                            <LabelTextArea
                              id="embed"
                              label={
                                (isMobile || isTablet) &&
                                orientation === 'landscape'
                                  ? ''
                                  : 'Embed code'
                              }
                              labelStyleProps={{
                                fontSize: 'sm',
                                textAlign: 'center',
                                marginRight: 0,
                              }}
                              labelPosition="top"
                              boxSizing="border-box"
                              flex={1}
                              name="embed"
                              placeholder="Paste your <iframe> embed code here..."
                              defaultValue={embedInput}
                              helpText={
                                isMobile && orientation === 'landscape'
                                  ? ''
                                  : `Embed any <iframe> content into your session here. You can usually find the embed code via buttons like "Share" or "Embed".`
                              }
                              onChange={(e) => setEmbedInput(e.target.value)}
                              backgroundColor="background.default"
                              fontSize="sm"
                              fontFamily="monospace"
                              textAlign="center"
                              error={embedInput && !embedCode}
                              errorMessage={
                                embedInput && !embedCode
                                  ? `Embed code unreadable, please make sure your code is formatted as an <iframe>.`
                                  : ''
                              }
                            />
                            <Button
                              icon="Done"
                              w={
                                isMobile && orientation === 'landscape'
                                  ? 10
                                  : 'auto'
                              }
                              isDisabled={!embedCode}
                              alignSelf="center"
                              onClick={async () => {
                                await onSaveClip({
                                  embed: embedCode,
                                  ...(src ? {} : { orientation: 'landscape' }),
                                });
                                setIsEditing(false);
                              }}
                            >
                              {isMobile && orientation === 'landscape'
                                ? ''
                                : 'Embed'}
                            </Button>
                          </Stack>
                        ) : (
                          <Flex justifyContent="center" my={2}>
                            <ProCta
                              label="Only available with"
                              ctaText="Upgrade to Embed"
                            />
                          </Flex>
                        )}

                        {/* <Button
                          size="sm"
                          variant="outline"
                          icon={isPreviewingEmbed ? 'Close' : 'Preview'}
                          onClick={() =>
                            setIsPreviewingEmbed(!isPreviewingEmbed)
                          }
                        >
                          {isPreviewingEmbed ? 'Close Preview' : 'Preview'}
                        </Button> */}
                      </Stack>
                    ) : null}
                    {isEditing && (
                      <LightMode>
                        <Button
                          mt={6}
                          icon="Cancel"
                          colorScheme="blackAlpha"
                          fontSize="sm"
                          onClick={() => setIsEditing(false)}
                        >
                          Stop Editing
                        </Button>
                      </LightMode>
                    )}
                  </Flex>
                  <Box position="absolute" top={4} right={4}>
                    <LightMode>
                      <Button
                        icon="ScreenRotation"
                        borderRadius="full"
                        w={7}
                        h={7}
                        minW={7}
                        px={0}
                        opacity={0.8}
                        colorScheme="blackAlpha"
                        transform={[
                          orientation === 'portrait'
                            ? 'rotate(45deg)'
                            : 'rotate(135deg)',
                        ]}
                        onClick={() => {
                          onSaveClip &&
                            onSaveClip({
                              orientation:
                                orientation === 'portrait'
                                  ? 'landscape'
                                  : 'portrait',
                            });
                        }}
                      />
                    </LightMode>
                  </Box>
                </Flex>
              )}
            {mediaType === 'image' && src ? (
              <Box
                {...orientationStyles}
                position="relative"
                filter={!isPlaying ? 'blur(10px)' : 'none'}
                transform={!isPlaying ? ['scale(1.1)'] : []}
                transition="all 0.3s"
              >
                <Box
                  {...containerStyle}
                  backgroundImage={`url(${src})`}
                  backgroundPosition="center"
                  backgroundSize="cover"
                  filter="blur(50px)"
                  transform={['scale(1.1)']}
                />
                <Box
                  {...containerStyle}
                  backgroundImage={`url(${src})`}
                  backgroundPosition="center"
                  backgroundSize="contain"
                  backgroundRepeat="no-repeat"
                />
              </Box>
            ) : mediaType === 'embed' && src ? (
              <Box
                {...orientationStyles}
                position="relative"
                // filter={!isPlaying ? 'blur(10px)' : 'none'}
                // transform={!isPlaying ? ['scale(1.1)'] : []}
                transition="all 0.3s"
              >
                <Box {...containerStyle}>
                  {isEditable && (
                    <Box position="absolute" bottom={6} right={6}>
                      <LightMode>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsEditing(true);
                            setIsPlaying(false);
                          }}
                          colorScheme="blackAlpha"
                          icon="Edit"
                          size="sm"
                          fontSize={{ base: 'sm', md: 'sm' }}
                          sx={{ svg: { marginRight: { base: 0, md: 1.5 } } }}
                          isLoading={uploadInProgress}
                          isDisabled={uploadInProgress}
                          w={{ base: 8, md: 'auto' }}
                        >
                          <Text display={{ base: 'none', md: 'inline' }}>
                            Edit Media
                          </Text>
                        </Button>
                      </LightMode>
                    </Box>
                  )}
                  <RenderHtml iframeOnly html={src || ''} />
                </Box>
              </Box>
            ) : refreshing ? (
              <Flex
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                backgroundColor="neutral.300"
                flexDirection="column"
                textAlign="center"
                p={6}
              >
                <Spinner color="neutral.700" size="sm" />
                {mediaType === 'video' && isEditable && (
                  <>
                    <Text color="neutral.700" mt={4} fontWeight="semibold">
                      Video processing
                    </Text>
                    <Text color="neutral.700" mt={2} fontSize="sm">
                      You can keep working on your session, this might take a
                      few minutes.
                    </Text>
                  </>
                )}
              </Flex>
            ) : playbackError ? (
              <Flex
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                textAlign="center"
              >
                <MdIcon name="Error" boxSize={6} color="neutral.200" mb={2} />
                <Text
                  color="neutral.200"
                  mb={6}
                  whiteSpace="break-spaces"
                  fontSize="sm"
                >
                  {'Oops, something went wrong!\nPlease refresh the page.'}
                </Text>
                <Button
                  icon="Refresh"
                  colorScheme="whiteAlpha"
                  onClick={() => window.location.reload()}
                  size="sm"
                >
                  Refresh Page
                </Button>
              </Flex>
            ) : (
              src && (
                <Flex
                  width="100%"
                  height="100%"
                  filter={blurBackground ? 'blur(10px)' : 'none'}
                  transform={blurBackground ? ['scale(1.1)'] : []}
                  transition="all 0.3s"
                >
                  {mediaType === 'audio' && stream && (
                    <Flex
                      position="absolute"
                      width="100%"
                      height="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <AudioVisualizer
                        stream={stream}
                        options={{
                          barColor: theme.colors.common.primary,
                          shadowColor: theme.colors.common.primaryTransparent,
                        }}
                      />
                    </Flex>
                  )}
                  {/* @ts-ignore */}
                  <ReactPlayer
                    ref={playerRef}
                    width="100%"
                    height="100%"
                    onDuration={handleOnDuration}
                    onProgress={handleOnProgress}
                    playing={isPlaying}
                    onReady={handleOnReady}
                    onPlay={handleOnPlay}
                    onEnded={() => setStream(null)}
                    progressInterval={100}
                    url={
                      PLATFORM === 'steppit'
                        ? src.replace('cdn.workshop.ws', 'cdn.steppit.com')
                        : src
                    }
                    playsinline
                    onError={handleOnError}
                    config={{
                      file: {
                        attributes: {
                          crossOrigin: 'anonymous',
                        },
                        // Subtitles
                        tracks: subtitles
                          ? [
                              {
                                kind: 'captions',
                                src: subtitles,
                                srcLang: 'en',
                                default: true,
                                label: 'Captions',
                              },
                            ]
                          : [],
                        hlsOptions: {
                          // @ts-ignore
                          xhrSetup: (xhr, url) => {
                            // xhr.withCredentials = true;
                            xhr.open(
                              'GET',
                              `${url}?t=${new Date().getTime()}`,
                              true
                            );
                          },
                        },
                      },
                    }}
                  />
                </Flex>
              )
            )}
          </Flex>
        </StyledVideo>
      </Flex>
      {showControls && !showSubtitlesEditor ? (
        <Flex
          {...containerStyle}
          alignItems="flex-end"
          justifyContent="center"
          pb={{ base: 3, md: 7 }}
          role="group"
          {...(embed
            ? {
                height: 'auto',
                width: { base: 'auto', lg: '100%' },
                sx: {
                  bottom: 'calc(50% - 50vh)',
                  top: 'calc(50% - 50vh)',
                  left: { base: 'calc(50% - 50vw)', lg: 0 },
                  right: { base: 'calc(50% - 50vw)', lg: 0 },
                  '@supports (height: 100dvh)': {
                    bottom: 'calc(50% - 50dvh)',
                    top: 'calc(50% - 50dvh)',
                  },
                },
              }
            : {})}
        >
          {/** CLIP CONTROLS (play/pause/skip) */}
          <Box
            {...containerStyle}
            height="100%"
            zIndex={2}
            backgroundColor="neutralAlpha.500"
            onClick={() => setIsPlaying(!isPlaying)}
            pointerEvents={uploadInProgress ? 'none' : 'auto'}
            opacity={
              !isPlaying || (progressPercent === 100 && mediaType !== 'image')
                ? 1
                : 0
            }
            transition="all 200ms ease-in"
            backdropFilter={blurBackground ? 'blur(10px)' : 'none'}
          >
            <Flex
              {...containerStyle}
              flexDirection="column"
              my="auto"
              overflow="scroll"
              sx={{
                maxHeight: 'calc(100vh - 90px)',
                '@supports (height: 100dvh)': {
                  maxHeight: 'calc(100dvh - 90px)',
                },
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none',
                '::-webkit-scrollbar': { display: 'none' },
              }}
              pointerEvents={!blurBackground ? 'none' : 'auto'}
            >
              <Flex flexDirection="column" my="auto" w="100%">
                {Boolean(!isTransitioning) && (
                  <>
                    {Boolean(summary) && (
                      <Flex
                        px={4}
                        pb={{ base: 3, md: 6 }}
                        mx="auto"
                        display={{
                          base: 'flex',
                          lg: orientation === 'landscape' ? 'flex' : 'none',
                        }}
                        maxW="800px"
                      >
                        <Text
                          color="text.light"
                          fontWeight="semibold"
                          textAlign="center"
                          fontSize={{ base: 'sm', md: 'md' }}
                        >
                          {summary}
                        </Text>
                      </Flex>
                    )}
                    <Flex
                      mx="auto"
                      display={{
                        base: 'flex',
                        lg: orientation === 'landscape' ? 'flex' : 'none',
                      }}
                      mb={{ base: 5, md: 8 }}
                    >
                      {Boolean(showPrevBtn && onClickPrev) && (
                        <Button
                          secondary
                          mx={1}
                          mt={2}
                          fontSize={{ base: 'sm', md: 'md' }}
                          size="sm"
                          onClick={onClickPrev}
                          icon="ArrowBack"
                          w={8}
                        />
                      )}
                      {Boolean(showNextBtn && onClickNext) && (
                        <Button
                          mx={1}
                          mt={2}
                          minWidth="100px"
                          fontSize={{ base: 'sm', md: 'md' }}
                          size="sm"
                          onClick={onClickNext}
                          icon="ArrowForward"
                          iconPosition="right"
                        >
                          Next
                        </Button>
                      )}
                    </Flex>
                  </>
                )}
                {Boolean(progressPercent === 100 && mediaType !== 'image') && (
                  <Flex alignItems="center" paddingX={4}>
                    <Flex flex={1} justify="center">
                      <Box
                        backgroundColor="neutralLightAlpha.300"
                        _hover={{ backgroundColor: 'neutralLightAlpha.400' }}
                        borderRadius="50%"
                        cursor="pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          playerRef.current?.seekTo(0);
                          setIsPlaying(true);
                        }}
                        padding={1}
                      >
                        <MdIcon color="white" name="Replay" boxSize={6} />
                      </Box>
                    </Flex>
                  </Flex>
                )}
                {Boolean(
                  progressPercent !== 100 &&
                    mediaType !== 'image' &&
                    mediaType !== 'embed'
                ) && (
                  <Flex alignItems="center">
                    <Flex w="20%" justifyContent="center">
                      <Box
                        backgroundColor="neutralLightAlpha.300"
                        _hover={{ backgroundColor: 'neutralLightAlpha.400' }}
                        borderRadius="50%"
                        cursor="pointer"
                        padding={1}
                        onClick={(e) => {
                          e.stopPropagation();
                          // Move 10 second backward
                          let prevTenSeconds =
                            (playerRef.current?.getCurrentTime() || 0) - 10;

                          playerRef.current?.seekTo(prevTenSeconds);
                        }}
                      >
                        <MdIcon name="Replay10" color="white" boxSize={6} />
                      </Box>
                    </Flex>
                    <Flex flex={1} justify="center">
                      <Box
                        backgroundColor="neutralLightAlpha.300"
                        _hover={{ backgroundColor: 'neutralLightAlpha.400' }}
                        borderRadius="50%"
                        cursor="pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsPlaying(!isPlaying);
                        }}
                        padding={1}
                      >
                        <MdIcon
                          color="white"
                          name={isPlaying ? 'Pause' : 'PlayArrow'}
                          boxSize={embed ? 6 : 8}
                        />
                      </Box>
                    </Flex>
                    <Flex w="20%" justifyContent="center">
                      <Box
                        backgroundColor="neutralLightAlpha.300"
                        _hover={{ backgroundColor: 'neutralLightAlpha.400' }}
                        borderRadius="50%"
                        cursor="pointer"
                        padding={1}
                        onClick={(e) => {
                          e.stopPropagation();
                          // Move 10 second forward
                          let nextTenSeconds =
                            (playerRef.current?.getCurrentTime() || 0) + 10;
                          if (nextTenSeconds < clipDuration) {
                            playerRef.current?.seekTo(nextTenSeconds);
                            return;
                          }
                          playerRef.current?.seekTo(clipDuration);
                          setIsPlaying(false);
                        }}
                      >
                        <MdIcon name="Forward10" color="white" boxSize={6} />
                      </Box>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
            <LightMode>
              <Stack
                alignItems="center"
                justifyContent="center"
                direction={
                  orientation === 'landscape'
                    ? 'row'
                    : { base: 'row', md: 'column' }
                }
                p={{ base: 2, md: 0 }}
                mb={{ base: 0, md: 6 }}
                pb={{
                  base: 0,
                  md: 12,
                  // lg:
                  //   progressPercent === 100 && orientation === 'portrait'
                  //     ? 0
                  //     : 12,
                }}
                position="absolute"
                bottom={{ base: 'auto', md: 0 }}
                width={{ base: 'auto', md: '100%' }}
                height={{
                  base: 'auto',
                  // lg:
                  //   progressPercent === 100 && orientation === 'portrait'
                  //     ? '100%'
                  //     : 'auto',
                }}
              >
                {/* {mediaType !== 'image' && !embed && (
                  <Button
                    colorScheme="whiteAlpha"
                    icon="Replay"
                    size="sm"
                    fontSize={{ base: 'sm', md: 'sm' }}
                    sx={{ svg: { marginRight: { base: 0, md: 1.5 } } }}
                    onClick={(e) => {
                      e.stopPropagation();
                      playerRef.current?.seekTo(0);
                      setIsPlaying(true);
                    }}
                  >
                    <Text display={{ base: 'none', md: 'inline' }}>
                      Restart
                    </Text>
                  </Button>
                )} */}
                {isEditable && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditing(true);
                      setIsPlaying(false);
                    }}
                    colorScheme="whiteAlpha"
                    icon="Edit"
                    size="sm"
                    fontSize={{ base: 'sm', md: 'sm' }}
                    sx={{ svg: { marginRight: { base: 0, md: 1.5 } } }}
                    isLoading={uploadInProgress}
                    isDisabled={uploadInProgress}
                    w={{ base: 8, md: 'auto' }}
                  >
                    <Text display={{ base: 'none', md: 'inline' }}>
                      Edit Media
                    </Text>
                  </Button>
                )}
              </Stack>
            </LightMode>
            {isEditable && srcHq && (
              <Flex
                position="absolute"
                top={0}
                right={0}
                cursor="pointer"
                px={4}
                py={4}
                transition="opacity 0.3s"
                _hover={{
                  opacity: 0.8,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsPlaying(false);
                  // Download video
                  const filename = srcHq.substring(srcHq.lastIndexOf('/') + 1);
                  saveAs(srcHq, filename);
                }}
              >
                <MdIcon name="Download" color="white" boxSize={6} />
              </Flex>
            )}
            {(subtitles || isEditable) && srcHq ? (
              <Flex position="absolute" top={0} right={isEditable ? 10 : 0}>
                <Flex alignItems="center">
                  <Flex
                    py={4}
                    pl={4}
                    pr={isEditable ? 0 : 4}
                    cursor="pointer"
                    transition="opacity 0.3s"
                    _hover={{
                      opacity: 0.8,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!subtitles && isEditable) {
                        setShowSubtitlesEditor(!showSubtitlesEditor);
                      } else {
                        setShowSubtitles(!showSubtitles);
                        localStorage.setItem(
                          'closedCaptions',
                          showSubtitles ? 'off' : 'on'
                        );
                      }
                    }}
                  >
                    <MdIcon
                      name={
                        showSubtitles ? 'ClosedCaption' : 'ClosedCaptionOff'
                      }
                      color="white"
                      boxSize={6}
                    />
                  </Flex>
                  {isEditable && (
                    <Flex
                      py={4}
                      pr={4}
                      cursor="pointer"
                      transition="opacity 0.3s"
                      _hover={{
                        opacity: 0.8,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowSubtitlesEditor(!showSubtitlesEditor);
                      }}
                    >
                      <Flex
                        height={4}
                        width={5}
                        marginLeft={-1}
                        alignItems="center"
                        justifyContent="center"
                        borderWidth={1.5}
                        borderLeftWidth={0}
                        borderColor="white"
                        borderTopRightRadius="full"
                        borderBottomRightRadius="full"
                      >
                        <MdIcon
                          name={subtitles ? 'Edit' : 'Add'}
                          color="white"
                          boxSize={3}
                        />
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            ) : null}
          </Box>

          {indicators}
        </Flex>
      ) : mediaType !== 'embed' ? (
        <Flex
          {...containerStyle}
          alignItems="flex-end"
          pb={{ base: 3, md: 7 }}
          pointerEvents="none"
        >
          {indicators}
        </Flex>
      ) : null}
      {showSubtitlesEditor ? (
        <>
          <Flex {...containerStyle} onClick={() => setIsPlaying(!isPlaying)} />
          {sortedSubtitles.map((s, idx) => (
            <SubtitleEntry
              key={`sub-text-${s.keyId}`}
              subtitle={s}
              isVisible={activeSubtitleIdx === idx}
              isPaused={!isPlaying}
              onChangeText={(newText: string) => {
                const newEditedSubtitles = [...sortedSubtitles];
                const newSubtitle = {
                  ...s,
                  text: newText,
                };
                newEditedSubtitles[idx] = newSubtitle;
                setEditedSubtitles(newEditedSubtitles);
              }}
              onRemoveSubtitle={() => {
                const newEditedSubtitles = [...sortedSubtitles].filter(
                  (ns, nIdx) => nIdx !== idx
                );
                setEditedSubtitles(newEditedSubtitles);
              }}
            />
          ))}
        </>
      ) : showSubtitles &&
        subtitlesText &&
        isPlaying &&
        !Boolean(progressPercent === 100 && mediaType !== 'image') ? (
        <Flex
          pointerEvents="none"
          position="absolute"
          bottom={16}
          left={0}
          right={0}
          zIndex={2}
          justifyContent="center"
        >
          <Text
            bg="rgba(0,0,0,0.5)"
            color="#fff"
            p={2}
            m={2}
            borderRadius="sm"
            textAlign="center"
            {...(dyslexicFontActive
              ? {
                  fontFamily: 'OpenDyslexic',
                  fontWeight: 'bold',
                  fontSize: '0.9rem',
                }
              : {
                  fontWeight: 'semibold',
                })}
          >
            {subtitlesText}
          </Text>
        </Flex>
      ) : null}
      {isEditable && (
        <>
          <Input
            ref={(e: HTMLInputElement | null) => {
              if (e) {
                fileInput.current = e;
              }
            }}
            type="file"
            accept={
              mediaType === 'audio'
                ? 'audio/wav,audio/mp3,audio/*'
                : 'video/mp4,video/x-m4v,video/*'
            }
            style={{ display: 'none' }}
            onChange={async (e) => {
              onUpload &&
                (await onUpload(
                  e,
                  id.toString(),
                  mediaType === 'audio' ? 'audio' : 'video'
                ));
              setIsEditing(false);
            }}
            onClick={(e) => e.stopPropagation()}
          />
          {!!recordingClip && onSaveClip && (
            <MediaRecorder
              isOpen
              clipType={recordingClip}
              onClose={() => setRecordingClip(null)}
              onSave={async (media) => {
                await onSaveClip(
                  recordingClip === 'video'
                    ? { videoBlob: media }
                    : { audioBlob: media }
                );
                setIsEditing(false);
              }}
              script={script}
            />
          )}
        </>
      )}
    </Box>
  );
};

const VideoClipsPlayer: React.FC<VideoClipsPlayerProps> = ({
  autoplay = true,
  autoPlayNext = false,
  currentStepIdx = 0,
  currentSessionStepId = null,
  loading = false,
  orientation = 'portrait',
  clips,
  isEditable = false,
  onUpload,
  qrBlob,
  embed,
  onSaveClip,
  onBlurBackground,
}) => {
  let startingStepIdx = currentStepIdx;
  if (startingStepIdx + 1 > clips.length) startingStepIdx = clips.length - 1;
  if (startingStepIdx < 0) startingStepIdx = 0;

  const [currentIdx, setCurrentIdx] = useState(startingStepIdx);
  const [savedSessionStepId, setSavedSessionStepId] =
    useState(currentSessionStepId);
  const [nextIdx, setNextIdx] = useState(startingStepIdx);

  // Update saved session step index when moving between session steps and if
  // our `currentStepIdx` prop changes, then navigate to that step (clip)
  useEffect(() => {
    // If the session step has changed, but other indicators show that this was the page's first render,
    // save the session step to the state without triggering a new clip animation
    if (
      ((currentStepIdx === 0 && currentIdx === 0) ||
        savedSessionStepId === null) &&
      currentSessionStepId !== savedSessionStepId
    ) {
      setSavedSessionStepId(currentSessionStepId);
      return;
    }

    // Don't do anything if the clip isn't set or hasn't changed
    if (currentStepIdx === undefined || currentStepIdx === currentIdx) {
      return;
    }

    // If the session step changes, change the current clip without a swiping animation, otherwise trigger
    // an animation when changing clips
    if (currentSessionStepId !== savedSessionStepId) {
      setNextIdx(0);
      setCurrentIdx(0);
      setSavedSessionStepId(currentSessionStepId);
    } else {
      // Set next index to trigger an animation in VideoPlayer, then set current index to switch clip
      setNextIdx(currentStepIdx);
      // Timeout to make sure the change of video clip source happens in the background while the sliding
      // animation between videos is happening over the top
      setTimeout(() => setCurrentIdx(currentStepIdx), 700);
    }
  }, [currentStepIdx, currentSessionStepId]);

  const orientationStyles = orientationStyleMapping[orientation];
  const currentStep = clips.length && clips[currentIdx];

  // Render a placeholder if no currentStep to render
  if (loading || !currentStep) {
    return (
      <Box {...orientationStyles} position="relative">
        <Flex
          {...containerStyle}
          backgroundColor="neutral.300"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="neutral.700" />
        </Flex>
      </Box>
    );
  }

  return (
    <VideoPlayer
      {...currentStep.data}
      id={currentStep.id}
      autoplay={autoplay}
      autoPlayNext={autoPlayNext}
      currentIdx={currentIdx}
      nextIdx={nextIdx}
      orientation={orientation}
      stepsTotal={clips.length}
      isEditable={isEditable}
      onUpload={onUpload}
      qrBlob={qrBlob}
      embed={embed}
      mediaType={currentStep.type}
      hasMedia={currentStep.hasMedia}
      onSaveClip={onSaveClip}
      onBlurBackground={onBlurBackground}
    />
  );
};

export default VideoClipsPlayer;
